import {
  GET_MEMBERS,
  GET_PROJECTS,
  PROJECT_LOADER,
  PROJECT_DETAILS,
  TASKS_DETAILS,
  PROJECT_STORY_PENDING,
  DETAILS_REFRESH,
  DETAILS_REFRESH_LOADER,
  GET_LAKES,
  PROMPT_HISTORY,
} from "./data.action";

const initialState = {
  projectDetails: [],
  tasksDetails: [],
  getMembers: [],
  getProjects: [],
  projectStoryPending: [],
  detailsRefresh: [],
  getLakes: [],
  promptHistory: [],
  projectLoader: false,
  detailsRefreshLoader: false,
};

export const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_DETAILS: {
      return { ...state, projectDetails: action.value };
    }
    case TASKS_DETAILS: {
      return { ...state, tasksDetails: action.value };
    }
    case GET_MEMBERS: {
      return { ...state, getMembers: action.value };
    }
    case GET_PROJECTS: {
      return { ...state, getProjects: action.value };
    }
    case PROJECT_LOADER: {
      return { ...state, projectLoader: action.value };
    }
    case PROJECT_STORY_PENDING: {
      return { ...state, projectStoryPending: action.value };
    }
    case DETAILS_REFRESH: {
      return { ...state, detailsRefresh: action.value };
    }
    case DETAILS_REFRESH_LOADER: {
      return { ...state, detailsRefreshLoader: action.value };
    }
    case GET_LAKES: {
      return { ...state, getLakes: action.value };
    }
    case PROMPT_HISTORY: {
      return { ...state, promptHistory: action.value };
    }

    default:
      return state;
  }
};
