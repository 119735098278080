import React, { useEffect } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import socket from "../../../service/socket";

const Default = ({ children }) => {
  const isAuth = window.localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    } else {
      console.log(socket.connected);
    }
  }, []);

  if (!isAuth) return null;

  return (
    <>
      {/* <div className='flex h-screen w-screen justify-center overflow-hidden'>
                <Sidebar />

                <div className='w-full'>

                    <Header />
                    {children}

                </div>

            </div> */}
      <div className="flex justify-around w-full h-screen overflow-hidden">
        <div className="w-[301px] border-r border-gray-300 h-full">
          <Sidebar />
        </div>

        <div className="w-full overflow-hidden">
          <div className="h-[72px] border-b border-gray-300 flex justify-between">
            <Header />
          </div>
          <div className="w-full">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Default;
