import React, { useEffect, useState } from "react";
import Organization from "./Organization";
import { TOGGLE } from "../../images";
import ActivePill from "../../components/Organization/ActivePill";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { loadGetProjects } from "../../store/data.action";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import PostRequest from "../../service/PostRequest";

const Projects = () => {
  const [loader, setLoader] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const _getProjects = useSelector((state) => state.dataReducer.getProjects);
  // console.log("_getProjects", _getProjects);
  const _projectLoader = useSelector(
    (state) => state.dataReducer.projectLoader
  );

  const dispatch = useDispatch();
  const params = useParams();

  const handleIconClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const deleteProject = async (projectId) => {
    if (window.confirm("Are you sure delete project?")) {
      try {
        const projectDelete = await PostRequest("/delete/project", {
          project: projectId,
        });

        if (projectDelete) {
          toast.success("Project Deleted Successfully");
          dispatch(loadGetProjects(params));
          setActiveIndex("");
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      setActiveIndex("");
    }
  };

  return (
    <Organization>
      <div
        className="overflow-y-scroll overflow-hidden"
        style={{ height: "calc(100vh - 218px)" }}
      >
        <table className="w-full overflow-y-scroll overflow-hidden">
          <tr className="border-b border-gray-300 text-[#5F5F5F] font-semibold">
            <td className="w-[250px] py-6 px-9">Project Name</td>
            <td className="w-[450px]">Project Description</td>
            <td className="w-[115px]">Active Tasks</td>
            <td className="w-[215px]">Client</td>
            <td className="w-[225px]">Client Domains</td>
            <td className="">Status</td>
            <td className=""></td>
          </tr>

          {_getProjects.map((project, index) => (
            <tr
              key={index}
              className=" text-[#555555] border-b border-gray-300 "
            >
              <td className="py-6 px-9">
                <p className="font-semibold text-black text-[16px]">
                  {project.name}
                </p>
                <p className="text-[14px]">
                  {moment(project.createdAt).format("DD MMM, YY")}
                  {/* {project.createdAt} */}
                </p>
              </td>
              <td>
                Small description of the project. It will be very short. User
                can create it or it can be generated (once) by the ai according
                to the project details page.
              </td>
              <td>35</td>
              <td>Solve4u</td>
              <td>solve4u.io, solve4u@gmail.com</td>
              <td>
                <ActivePill title={project.status} />
              </td>
              <td className="relative">
                <Icon
                  icon="iconamoon:menu-kebab-vertical-bold"
                  fontSize={30}
                  className="cursor-pointer"
                  onClick={() => handleIconClick(index)}
                  // onClick={() => setActiveIndex(!activeIndex)}
                />

                {index === activeIndex && (
                  <div
                    className="rounded-md py-2 origin-top-right 
                       bg-white shadow-lg ring-1 ring-black ring-opacity-5 pl-2 pr-8 absolute ms-[-115px]"
                  >
                    <button
                      className="text-red-600 flex items-center gap-2"
                      onClick={() => deleteProject(project._id)}
                    >
                      <Icon icon="weui:delete-outlined" fontSize={20} />
                      <span className="font-semibold text-[18px]">Delete</span>
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </table>
        <div className="flex justify-center mt-5">
          {_projectLoader && <Loader />}
        </div>
      </div>
    </Organization>
  );
};

export default Projects;
