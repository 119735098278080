// import React from "react";
// import { createRoot } from "react-dom/client";
// import Markdown from "react-markdown";
// import remarkGfm from "remark-gfm";
// import "./ai-response.css";

// const MarkdownAiResponse = (props) => {
//   if (props.markdownAiResponse == "") {
//     return (
//       <div className="flex justify-center">
//         There are no any data generate yet!
//       </div>
//     );
//   }

//   return (
//     <div className="ai-response-body mb-5">
//       <Markdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
//         {props.markdownAiResponse}
//       </Markdown>
//     </div>
//   );
// };

// export default MarkdownAiResponse;

import React from "react";
import sanitizeHtml from "sanitize-html";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./ai-response.css";
import { useSelector } from "react-redux";
import Loader from "../Loader";

const MarkdownAiResponse = (props) => {
  const _detailsRefresh = useSelector(
    (state) => state.dataReducer.detailsRefreshLoader
  );

  if (!props.markdownAiResponse) {
    return (
      <div className="flex justify-center">There is no data generated yet!</div>
    );
  }

  if (_detailsRefresh) {
    return (
      <div className="flex justify-center">
        <Loader height="40px" width="40px" />
      </div>
    );
  }

  const cleanHtml = sanitizeHtml(props.markdownAiResponse, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      "img",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "p",
      "ul",
      "ol",
      "li",
      "strong",
      "em",
      "blockquote",
      "code",
      "pre",
      "a",
      "b",
      "i",
      "table",
    ]),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ["src", "alt"],
      a: ["href", "title"],
    },
    allowedSchemes: ["http", "https", "mailto"],
  });

  return (
    <div className="ai-response-body mb-5">
      <ReactMarkdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
        {cleanHtml}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownAiResponse;
