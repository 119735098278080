import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostRequest from "../../service/PostRequest";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getNewLakes } from "../../store/data.action";
import Loader from "../../components/Loader";
import { Formik } from "formik";
import * as Yup from "yup";

const CreateNewLake = (props) => {
  const [loader, setLoader] = useState(false);
  const [createLakeLoader, setCreateLakeLoader] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [newPreview, setNewPreview] = useState("");
  const [createNewSource, setCreateNewSource] = useState([
    "All Stream Entries",
  ]);
  const [reservoirs, setReservoirs] = useState([]);

  const [lakeName, setLakeName] = useState("");
  const [openCreateLake, setOpenCreateLake] = useState(true);
  const [newLakeName, setNewLakeName] = useState("");

  const [activeTab, setActiveTab] = useState("General");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const _getLakes = useSelector((state) => state.dataReducer.getLakes);

  const initialValues = {
    name: "",
    lake: "",
  };

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    name: Yup.string().required("Lake Name is required"),
  });

  const handleCreateSources = (option) => {
    if (createNewSource.includes(option)) {
      setCreateNewSource(createNewSource.filter((item) => item !== option));
    } else {
      // let updatedSource = [...createNewSource, option];
      // if (option === "All Stream Entries") {
      //   updatedSource = updatedSource.filter(
      //     (item) => item !== "Only New Stream Entries"
      //   );
      // } else if (option === "Only New Stream Entries") {
      //   updatedSource = updatedSource.filter(
      //     (item) => item !== "All Stream Entries"
      //   );
      // }
      // setCreateNewSource(updatedSource);
      setCreateNewSource([...createNewSource, option]);
    }
  };

  const handleLakeName = (event) => {
    setNewLakeName(event.target.value);
  };

  const handlePrompt = (event) => {
    setTextareaValue(event.target.value);
  };

  const promptActive = () => {
    if (!lakeName || lakeName == "") {
      return false;
    }

    if (!createNewSource || createNewSource.length == 0) {
      return false;
    }

    return true;
  };

  const previewActive = () => {
    if (textareaValue == "") {
      return false;
    }

    return true;
  };

  const isFormValid = () => {
    if (!lakeName || lakeName == "") {
      return false;
    }

    if (!createNewSource || createNewSource.length == 0) {
      return false;
    }

    if (textareaValue == "") {
      return false;
    }

    if (newPreview == "") {
      return false;
    }

    return true;
  };

  const promptUpdate = async () => {
    if (loader) return;

    if (!lakeName || lakeName == "") {
      toast.error("Please enter Lake name");
      setActiveTab("General");
      return false;
    }

    if (!createNewSource || createNewSource.length == 0) {
      setActiveTab("General");
      toast.error("Please select minimum 1 resource");
      return false;
    }

    if (textareaValue == "") {
      toast.error("Please enter prompt");
      return false;
    }

    try {
      const data = {
        project: params.project_id,
        prompt: textareaValue,
        sources: createNewSource,
      };

      setLoader(true);

      const playgroundResponse = await PostRequest("/prompt/preview", data);
      if (playgroundResponse) {
        setNewPreview(playgroundResponse);
        setActiveTab("Preview");
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoader(false);
    }
  };

  const promptPreviewToLack = async () => {
    if (!lakeName || lakeName == "") {
      toast.error("Please enter Lake name");
      setActiveTab("General");
      return false;
    }

    if (!createNewSource || createNewSource.length == 0) {
      setActiveTab("General");
      toast.error("Please select atleas 1 resource");
      return false;
    }

    if (textareaValue == "") {
      setActiveTab("Prompt");
      toast.error("Please enter prompt");
      return false;
    }

    if (newPreview == "") {
      toast.error("Please enter preview");
      return false;
    }

    const data = {
      project: params?.project_id,
      preview: newPreview,
      prompt: textareaValue,
      title: lakeName,
      resources: createNewSource,
    };

    setCreateLakeLoader(true);
    try {
      const createNewLake = await PostRequest("/lake/create", data);
      console.log(createNewLake, "createNewLake");
      if (createNewLake) {
        toast.success("New Lake Created.");
        setOpenCreateLake(false);
        dispatch(getNewLakes({ project: params.project_id }));
        navigate(
          `/${params.organization_id}/${params.project_id}/project-details/${createNewLake._id}`
        );
      }
    } catch (error) {
      toast.error(error);
    }
    setCreateLakeLoader(false);
  };

  const closeNewCreateLake = () => {
    setOpenCreateLake(false);
    navigate(-1); // Navigate back to the previous page
  };

  const getReservoir = async () => {
    try {
      const response = await PostRequest("/reservoirs", {
        project: params.project_id,
      });

      if (response) {
        setReservoirs(response);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getReservoir();
  }, []);

  return (
    <>
      {openCreateLake && (
        <div className=" border-b border-gray-300  text-[#555555] font-semibold text-base">
          <div className="pt-4 pl-6 pr-6 flex items-center justify-between">
            <button className="flex items-center gap-3 text-black text-[16px]">
              <button className="cursor-pointer p-2 rounded-md hover:bg-[#f5f5f5]">
                <Icon
                  icon="uiw:left"
                  fontSize={22}
                  onClick={closeNewCreateLake}
                />
              </button>
              <div className="cursor-text">Create New Lake</div>
            </button>

            <div className="flex items-center gap-2.5">
              <button
                className="p-2 cursor-pointer rounded-md text-[16px] hover:bg-[#f5f5f5]"
                onClick={closeNewCreateLake}
              >
                Cancel
              </button>

              <button
                disabled={!isFormValid()}
                className={`p-2 w-fit flex ${
                  isFormValid()
                    ? "cursor-pointer"
                    : "opacity-50 cursor-not-allowed"
                }  rounded-md text-[16px] hover:bg-[#4F9FFF] bg-[#2387FF] text-white`}
                onClick={promptPreviewToLack}
              >
                {createLakeLoader && (
                  <div className="mt-1 me-2">
                    <Loader color="white" height="17px" width="17px" />
                  </div>
                )}
                <span>Create Lake</span>
              </button>
            </div>
          </div>

          <div className="mt-4 flex gap-5 pb-3 pl-6 pr-6">
            <button
              onClick={() => setActiveTab("General")}
              className={`cursor-pointer ${
                activeTab == "General" ? "text-[#155199] activeEditPrompt" : ""
              }`}
            >
              General
            </button>

            <button
              disabled={!promptActive()}
              onClick={() => setActiveTab("Prompt")}
              className={`${
                promptActive()
                  ? "cursor-pointer"
                  : "opacity-50 cursor-not-allowed"
              } ${activeTab == "Prompt" && "text-[#155199] activeEditPrompt"}`}
            >
              Prompt
            </button>

            <button
              disabled={!previewActive()}
              onClick={() => setActiveTab("Preview")}
              className={`${
                previewActive()
                  ? "cursor-pointer"
                  : "opacity-50 cursor-not-allowed"
              } ${activeTab == "Preview" && "text-[#155199] activeEditPrompt"}`}
            >
              Preview
            </button>
          </div>
        </div>
      )}

      <div style={{ display: activeTab == "General" ? "block" : "none" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={DisplayingErrorMessagesSchema}
          onSubmit={(values, { setSubmitting }) => {
            setLakeName(values.name);
            setActiveTab("Prompt");
            setSubmitting(false);
          }}
        >
          {({
            errors,
            touched,
            isValid,
            dirty,
            handleSubmit,
            getFieldProps,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-6 text-[#555555] overflow-y-auto overflow-hidden h-[600px]">
                <div className="bg-[#FFF2C2] rounded-md p-2.5 flex items-center gap-6">
                  <Icon
                    icon="heroicons:light-bulb"
                    color="#191919"
                    fontSize={24}
                    className="ms-6"
                  />
                  <p className="w-[650px] leading-6 text-[15px] text-[#191919]">
                    Ai takes Sources to get data for your prompt. So choose your
                    lake sources wisely to get the absolute results you want.
                  </p>
                </div>

                <div className="mt-6">
                  <div className="text-sm mb-2">
                    Lake Name
                    <span className="text-pink-700 text-[16px] ps-1">*</span>
                  </div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Lake Name"
                    onChange={(event) => handleLakeName(event)}
                    {...getFieldProps("name")}
                    className={`mb-3 border ${
                      errors.name && touched.name
                        ? "border-red-500"
                        : "border-gray-500"
                    } w-full rounded px-3 py-2 placeholder-font-normal rounded-t-md focus:outline-none focus:z-10 sm:text-sm`}
                  />
                  {/* <ErrorMessage name="name" component="div" className="text-red-500 text-sm mb-2" /> */}

                  <div className="text-sm mb-2">
                    Sources
                    <span className="text-pink-700 text-[16px] ps-1">*</span>
                  </div>

                  <div
                    className={`mb-3 border ${
                      errors.lake && touched.lake
                        ? "border-red-500"
                        : "border-gray-500"
                    } w-full rounded px-3  placeholder-font-normal rounded-t-md focus:outline-none focus:z-10 sm:text-sm`}
                  >
                    <select
                      name="lake"
                      {...getFieldProps("lake")}
                      value={
                        createNewSource.length > 0 && reservoirs.length > 0
                          ? createNewSource[createNewSource.length - 1] &&
                            reservoirs[reservoirs.length - 1]
                          : ""
                      }
                      onChange={(e) => handleCreateSources(e.target.value)}
                      className={` border ${
                        errors.lake && touched.lake
                          ? "border-red-500"
                          : "border-gray-500"
                      } w-full rounded px-3 py-2 placeholder-font-normal rounded-t-md focus:outline-none focus:z-10 sm:text-sm`}
                    >
                      {/* <option label="Select To Add Source" /> */}
                      {/* <option value="Only New Stream Entries">
                        Only New Stream Entries
                        </option> */}

                      <optgroup label="Lakes">
                        <option value="All Stream Entries">
                          All Stream Entries
                        </option>
                        {_getLakes.map((lake, index) => (
                          <option key={index} value={lake.title}>
                            {lake.title}
                          </option>
                        ))}
                      </optgroup>
                      <optgroup label="Reservoirs">
                        {reservoirs.map((reservoir, i) => (
                          <option key={i} value={reservoir.title}>
                            {reservoir.title}
                          </option>
                        ))}
                      </optgroup>
                    </select>
                  </div>
                  {/* <ErrorMessage name="lake" component="div" className="text-red-500 text-sm mb-2" /> */}

                  {createNewSource.length > 0 && (
                    <div>
                      {createNewSource.map((option) => (
                        <div
                          key={option}
                          className="flex items-center justify-between bg-[#d3e7ff]
                              mb-3 border border-[#2387FF]
                              w-full rounded px-3 py-2"
                        >
                          <span>{option}</span>
                          <button onClick={() => handleCreateSources(option)}>
                            <Icon
                              icon="gridicons:cross"
                              fontSize={24}
                              color="black"
                            />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="flex justify-end mt-6">
                    <button
                      type="submit"
                      className={`cursor-pointer flex items-center gap-2 hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2 ${
                        !(isValid && dirty && createNewSource.length > 0)
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      disabled={
                        !(isValid && dirty && createNewSource.length > 0)
                      }
                    >
                      {loader && (
                        <Loader color="white" height="17px" width="17px" />
                      )}
                      Setup Prompt
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

      <div style={{ display: activeTab == "Prompt" ? "block" : "none" }}>
        <div className="p-6 text-[#555555] h-[100vh]">
          <div className="bg-[#FFF2C2] rounded-md p-2.5 flex items-center gap-6">
            <Icon
              icon="heroicons:light-bulb"
              color="#191919"
              fontSize={24}
              className="ms-6"
            />
            <p className="w-[650px] leading-6 text-[15px] text-[#191919]">
              Ai takes Sources to get data for your prompt. So choose your lake
              sources wisely to get the absolute results you want.
            </p>
          </div>
          <div className="mt-6">
            <div className="text-sm mb-2 font-semibold">
              User Prompt
              <span className="text-pink-700 text-[16px] ps-1">*</span>{" "}
            </div>
            <textarea
              value={textareaValue}
              onChange={handlePrompt}
              className="border border-[#777] p-3 rounded w-full h-[350px]"
            />
          </div>

          <div className="flex justify-end mt-6">
            <button
              disabled={!previewActive()}
              // className="cursor-pointer flex items-center gap-2
              //       hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2"
              onClick={promptUpdate}
              className={`${
                previewActive()
                  ? "cursor-pointer"
                  : "opacity-50 cursor-not-allowed"
              } flex items-center gap-2
                    hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2`}
            >
              {loader && <Loader color="white" height="17px" width="17px" />}
              Preview
            </button>
          </div>
        </div>
      </div>

      <div style={{ display: activeTab == "Preview" ? "block" : "none" }}>
        <div className="p-6 text-[#555555] h-[100vh]">
          <div className="bg-[#FFF2C2] rounded-md p-2.5 flex items-center gap-6">
            <Icon
              icon="heroicons:light-bulb"
              color="#191919"
              fontSize={24}
              className="ms-6"
            />
            <p className="w-[650px] leading-6 text-[15px] text-[#191919]">
              Ai takes Sources to get data for your prompt. So choose your lake
              sources wisely to get the absolute results you want.
            </p>
          </div>

          <div className="mt-6">
            <div className="text-sm mb-2 font-semibold">
              AI Response Preview
              {/* <span className="text-pink-700 text-[16px] ps-1">*</span> */}
            </div>
            <textarea
              value={newPreview}
              onChange={(e) => setNewPreview(e.target.value)}
              className="border border-t-[#777] outline-none border-b-[#777] p-3 rounded w-full h-[350px]"
            />
          </div>

          {/* <div className="flex justify-end mt-6">
            <button
              onClick={promptUpdate}
              // onClick={promptPreviewToLack}
              className="cursor-pointer flex items-center gap-2 hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2"
            >
              {loader && <Loader color="white" height="17px" width="17px" />}
              Regenerate Preview
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CreateNewLake;
