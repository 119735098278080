import React, { Children, useEffect, useRef, useState } from "react";
import Default from "../../components/theme/Default/Default";
import AIChat from "../../components/AIChat";
import { EDIT, FILTER, REFRESH } from "../../images";
import TaskMenu from "../../components/Tasks/TaskMenu";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactDatePicker from "react-datepicker";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { loadPromptHistory, loadTasksDetails } from "../../store/data.action";
import PostRequest from "../../service/PostRequest";
import moment from "moment";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div
              className="relative w-[700px] h-auto bg-white rounded-[10px] overflow-hidden"
              ref={modalRef}
            >
              <div className="p-6">{children}</div>
              <button
                className="absolute top-0 right-0 m-4 mt-[22px] text-gray-500 hover:text-gray-600"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Tasks = ({ children, setIsStatus = () => {}, isStatus }) => {
  const params = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [loader, setLoader] = useState(false);
  // const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("isStatus", isStatus);

  const organizationId = params["organization_id"];
  const projectId = params["project_id"];

  const displayStatus = (e) => {
    setIsStatus(e.target.value);
  };

  // const tasksPromptUpdate = (task) => {
  //   switch (task) {
  //     case "in-progresstasks":
  //       return "inprogressTasks";

  //     case "not-startedtasks":
  //       return "notStaredTasks";

  //     case "completedtasks":
  //       return "completedTasks";

  //     default:
  //       return task;
  //   }
  // };

  // var type = window.location.href.split("/");

  const promptUpdate = async () => {
    const data = {
      // type: tasksPromptUpdate(type[type.length - 1] + type[type.length - 2]),
      type: isStatus,
      project: params.project_id,
      prompt: textareaValue,
    };
    // console.log(data);

    setLoader(true);
    try {
      const response = await PostRequest("/prompt/update", data);
      if (response) {
        dispatch(loadTasksDetails({ project: params.project_id }));
        // window.localStorage.setItem(
        //   `${type[type.length - 1]}_prompt_${isStatus}`,
        //   textareaValue
        // );
        // navigate(`/tasks/general-list/${status}`);
        toggleModal(false);
        toast.success("Update Prompt");
      }
    } catch (error) {
      toast.error(error);
    }
    setLoader(false);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handlePrompt = (event) => {
    setTextareaValue(event.target.value);
  };

  // const defaultValues = window.localStorage.getItem()

  const defaultValue = () => {
    switch (isStatus) {
      case "inprogressTasks":
        return "List All Inprogress Tasks for the project";

      case "notStaredTasks":
        return "List All Pending or  Not Started Tasks for the project";

      case "completedTasks":
        return "List All Completed Tasks for the project";

      default:
        return "";
    }
  };

  const promptHistory = () => {
    const data = {
      project: projectId,
      date: moment(selectedDate).format("DD-MM-YYYY"),
    };

    try {
      dispatch(loadPromptHistory(data, true));
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (
      moment().format("DDMMYYYY") !== moment(selectedDate).format("DDMMYYYY")
    ) {
      promptHistory();
    }
  }, [selectedDate]);

  // useEffect(() => {
  //   setStatus(isStatus);
  // }, [isStatus]);

  return (
    <>
      <Default>
        <div className="flex">
          <div className="w-full">
            <TaskMenu
              organizationId={organizationId}
              projectId={projectId}
              // status={status}
            />

            <div className="pl-[24px] py-[16px] pr-[16px] border-b border-gray-300 flex items-center justify-between text-[#555555] font-semibold text-base">
              <div className="flex gap-3 items-center">
                <div className="flex gap-2 items-center">
                  <div className="text-[16px] font-semibold">Status:</div>
                  <div className="w-[190px] px-2 h-10 border border-gray-400 rounded-md flex items-center justify-between">
                    <select
                      name="project-name"
                      id="pname"
                      className="w-[190px] h-9 cursor-pointer text-[16px] font-semibold"
                      onChange={displayStatus}
                      defaultValue={isStatus}
                    >
                      {/* <option value={status}>In Progress</option>
                      <option value={status}>Not Started</option>
                      <option value={status}>Completed</option> */}
                      <option value="inprogressTasks">In Progress</option>
                      <option value="notStaredTasks">Not Started</option>
                      <option value="completedTasks">Completed</option>
                    </select>
                  </div>
                </div>

                <div className="flex gap-2 items-center">
                  <div>Date:</div>

                  {/* {_tasksDetails
                ? moment(_tasksDetails.updatedAt).format("DD MMM, YYYY")
                : "Not Specified"} */}
                  {/* <span>{moment().format("DD MMM, YYYY")}</span>
              <span className="ps-1">(Today)</span> */}
                  <ReactDatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat={"dd MMM, YYYY"}
                    minDate={new Date("02-01-2020")}
                    maxDate={new Date()}
                    className="px-3 py-2.5 border text-sm border-[#878787] rounded-md outline-none w-48"
                  />
                </div>

                <div className="ms-4 flex items-center gap-2">
                  <img src={FILTER} alt="filter" />
                  <p>Filter</p>
                </div>
              </div>

              <div className="flex items-center gap-5">
                <div
                  className="flex gap-2 p-2 cursor-pointer
                 rounded-md hover:bg-[#f5f5f5]"
                  onClick={toggleModal}
                >
                  <img
                    src={EDIT}
                    alt="edit"
                    className="w-[24px] h-[24px] cursor-pointer"
                  />
                  <p className="cursor-pointer">Edit Prompt</p>
                </div>

                <Modal
                  className="w-[500px] h-[700px]"
                  isOpen={isOpen}
                  onClose={toggleModal}
                >
                  <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
                    Edit Prompt
                  </h1>
                  <p className="text-[16px] text-[#555555] font-normal mb-6 tracking-[0.03em]">
                    Customize the prompt to get more control over the output
                    content of this page. This prompt helps the AI to give you
                    the expected result you want.
                  </p>
                  <textarea
                    className="outline-none border mb-6 font-normal border-[#878787] rounded-md p-3 w-[652px]
                     h-[252px] text-[16px] text-[#555555]"
                    placeholder=" The default prompt..."
                    onChange={handlePrompt}
                    defaultValue={defaultValue()}
                  ></textarea>
                  <div className="flex justify-between items-center">
                    <div
                      className="cursor-pointer flex items-center gap-[10px] text-base text-[#555555]
                    p-2 rounded-md hover:bg-[#f5f5f5]"
                    >
                      <img src={REFRESH} alt="reset" /> Reset Changes
                    </div>
                    <div className="flex items-center text-base text-[#555555] gap-[10px]">
                      <div
                        className="cursor-pointer"
                        onClick={() => setIsOpen(false)}
                      >
                        <div className="p-2 rounded-md hover:bg-[#f5f5f5]">
                          Cancel
                        </div>
                      </div>
                      <button
                        className="cursor-pointer flex items-center gap-2
                        hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2"
                        onClick={promptUpdate}
                      >
                        {loader && (
                          <Loader color="white" height="17px" width="17px" />
                        )}
                        Apply Prompt
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>

            <div className="h-[calc(100vh-218px)] ">{children}</div>
          </div>
          <div className="w-[550px] customGray">
            <AIChat />
          </div>
        </div>
      </Default>
    </>
  );
};

export default Tasks;
