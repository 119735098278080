import React, { useState } from "react";
import PostRequest from "../../service/PostRequest";
import { Bounce, toast } from "react-toastify";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const _getLakes = useSelector((state) => state.dataReducer.getLakes);

  const loginData = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    var json = JSON.stringify(object);
    // console.log('login', object);
    setLoader(true);

    try {
      const response = await PostRequest("/member/login", object);
      // console.log("responseLogin", response);

      if (response) {
        toast.success("Welcome Back!");
        window.localStorage.setItem("token", response.token);
        window.localStorage.setItem("userName", response.name);
        window.localStorage.setItem("organization", response.organization);

        if (response.activeProject) {
          // navigate(
          //   `/${response.organization}/${response.activeProject}/project-details/updates`
          // );
          navigate(
            `/${response.organization}/${response.activeProject}/project-details/updates`
          );
        } else {
          navigate(`/${response.organization}/organization/general`);
        }
      }
    } catch (error) {
      toast.error(error);
    }
    setLoader(false);
  };

  const loginWithGoogle = async (credentialResponse) => {
    // const credentialResponseDecode = jwtDecode(credentialResponse.credential);
    // console.log("credentialResponse", credentialResponseDecode);

    try {
      const response = await PostRequest("/member/loginWithGoogle", {
        token: credentialResponse.credential,
      });
      console.log("response", response);

      if (response) {
        window.localStorage.setItem("token", response.token);
        window.localStorage.setItem("userName", response.name);
        window.localStorage.setItem("organization", response.organization);

        if (response.isUpdated == false) {
          navigate("/update-profile");
        } else {
          if (response.activeProject) {
            navigate(
              `/${response.organization}/${response.activeProject}/project-details/updates`
            );
          } else {
            navigate(`/${response.organization}/organization/general`);
          }
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div className="bgImage"></div>
        <div className="min-h-screen flex items-center justify-start bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          <div className="p-8">
            {/* <div className="max-w-md w-full bg-white border border-gray-200 rounded-lg shadow-md p-8"> */}
            <div>
              <h2 className=" text-start text-xl font-semibold text-[#191919]">
                Login / Signup
              </h2>
            </div>
            {/* <form className="mt-4" onSubmit={loginData}>
              <div className="shadow-sm">
                <div className="mb-3">
                  <input
                    name="email"
                    type="email"
                    className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
                <div>
                  <input
                    name="password"
                    type="password"
                    className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="mt-5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {loader && (
                    <Loader color="white" height="18px" width="18px" />
                  )}
                  &nbsp;&nbsp; Log in
                </button>
              </div>

              <div className="mt-6 text-center text-1xl text-gray-900">
                New User?
                <NavLink to={"/register"}>
                  <span className="underline">Create Account.</span>
                </NavLink>
              </div>
            </form> */}

            <div className="mt-4 w-[250px]">
              <GoogleLogin
                onSuccess={loginWithGoogle}
                // onSuccess={(credentialResponse) => {
                //   const credentialResponseDecode = jwtDecode(
                //     credentialResponse.credential
                //   );
                //   console.log("credentialResponse", credentialResponseDecode);
                // }}
                // onError={() => {
                //   console.log("Login Failed");
                // }}
              />
            </div>

            <div className="w-[350px] mt-4 text-[14px] text-[#555555] tracking-[3%] leading-6">
              By continuing you agree with our 
              <span className="font-semibold">
                Terms of Service and Privacy Policy.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
