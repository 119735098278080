export const LOGOTEXT = require("../src/assets/images/Flowr.life.png");
export const LOGO = require("../src/assets/images/Frame 4.png");
export const INBOX = require("../src/assets/images/Inbox.png");
export const TEAM = require("../src/assets/images/Team.png");
export const HOME = require("../src/assets/images/Home.png");
export const EXPAND = require("../src/assets/images/expand_more.png");
export const SOURCE = require("../src/assets/images/source.png");
export const HISTORY = require("../src/assets/images/history.png");
export const TASK = require("../src/assets/images/Task.png");
export const CHART_BAR = require("../src/assets/images/chart_bar_line.png");
export const SETTING = require("../src/assets/images/Settings.png");
export const FEEDBACK = require("../src/assets/images/Feedback.png");
export const EDIT = require("../src/assets/images/edit_2_line.png");
export const TOGGLE = require("../src/assets/images/Frame 26994.png");
export const SEND = require("../src/assets/images/Icon-btn-primary.png");
export const COPY = require("../src/assets/images/icon-copy-small.png");
export const RETURN = require("../src/assets/images/icon-btn-small.png");
export const FILTER = require("../src/assets/images/filter_list.png");
export const REFRESH = require("../src/assets/images/refresh.png");
export const CHECK = require("../src/assets/images/check.png");
export const CLOSE = require("../src/assets/images/close.png");
export const FLOWER = require("../src/assets/images/flower.png");
export const ORGANIZATION = require("../src/assets/images/organization.png");
export const ADD = require("../src/assets/images/add.png");
export const IDEA = require("../src/assets/images/idea.png");
export const ARROW_BACK = require("../src/assets/images/arrowback.png");
export const PROMPT = require("../src/assets/images/prompt.png");
export const REFRESH1 = require("../src/assets/images/refresh1.png");
export const IDEADARK = require("../src/assets/images/emoji_objects.svg");
export const LINK = require("../src/assets/images/link.png");
export const UNDO = require("../src/assets/images/refreshLeft.png");
export const ADDONE = require("../src/assets/images/Vector.png");
export const SAVE = require("../src/assets/images/save.png");
export const RESERVOIRS = require("../src/assets/images/reservoirs.png");
