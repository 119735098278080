import React from "react";
import { COPY, HISTORY, RETURN, SEND, TOGGLE } from "../images";

const AIChat = () => {
  const getMessages = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    console.log("AI chat", object);
  };

  return (
    <>
      <div className="xl:max-w-[551px] lg:max-w-[300px] relative h-screen border-l border-gray-300 tracking-[0.03em]">
        <div className="flex items-center justify-between border-b border-gray-300">
          <div className="flex items-center gap-2 pl-4 py-4">
            <div className="w-10 h-10 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex items-center justify-center">
              <p className="font-bold text-white text-2xl">A</p>
            </div>
            <p className="text-[#555555] text-base font-semibold">
              AI Assistant
            </p>
          </div>
          <div className="flex items-center">
            <img src={HISTORY} alt="history" />
            <img src={TOGGLE} alt="toggle" />
          </div>
        </div>
        {/* <div
          style={{ height: "calc(82% - 70px)" }}
          className="overflow-y-scroll overflow-x-hidden text-[#555555] p-6"
        >
          <div className="w-full h-auto m-auto mt-5 text-[16px]">
            <div className="xl:w-[450px] lg:w-[350] h-auto py-4 px-5 rounded-xl bg-[#EBF5FF] border border-[#B7D3F4]">
              <p>Hi Abdullah, Welcome back!</p>
              <p className="mb-4">You have three works to do today:</p>
              <p>
                1. Meeting with assistant designers about the new design of
                Clinic portal.
              </p>
              <p>2. Check everything in the new design is working ok.</p>
              <p>3. Fix the prototype and check it.</p>
              <p className="mt-4">
                How is your work going? Is there any update today?
              </p>
            </div>
            <div className="flex items-center">
              <img src={COPY} alt="copy" />
              <img src={RETURN} alt="return" />
            </div>
          </div>

          <div className="xl:w-[502px] text-[#555555] m-auto my-2 font-semibold grid gap-y-2 justify-items-end text-right">
            <p className="border border-[#878787] rounded-md w-fit px-4 py-[10px]">
              Write a document in the project story
            </p>
            <p className="border border-[#878787] rounded-md w-fit px-4 py-[10px]">
              Show all of your works
            </p>
            <p className="border border-[#878787] rounded-md w-fit px-4 py-[10px]">
              Show todays update
            </p>
          </div>

          <div className="w-full h-auto m-auto mt-5 text-[16px]">
            <div className="xl:w-[450px] lg:w-[350] h-auto py-4 px-5 rounded-xl bg-[#EBF5FF] border border-[#B7D3F4]">
              <p>Hi Abdullah, Welcome back!</p>
              <p className="mb-4">You have three works to do today:</p>
              <p>
                1. Meeting with assistant designers about the new design of
                Clinic portal.
              </p>
              <p>2. Check everything in the new design is working ok.</p>
              <p>3. Fix the prototype and check it.</p>
              <p className="mt-4">
                How is your work going? Is there any update today?
              </p>
            </div>
            <div className="flex items-center">
              <img src={COPY} alt="copy" />
              <img src={RETURN} alt="return" />
            </div>
          </div>

          <div className="xl:w-[502px] text-[#555555] m-auto my-2 font-semibold grid gap-y-2 justify-items-end text-right">
            <p className="border border-[#878787] rounded-md w-fit px-4 py-[10px]">
              Write a document in the project story
            </p>
            <p className="border border-[#878787] rounded-md w-fit px-4 py-[10px]">
              Show all of your works
            </p>
            <p className="border border-[#878787] rounded-md w-fit px-4 py-[10px]">
              Show todays update
            </p>
          </div>
        </div>
        <div className="pb-[24px] px-[24px] w-full absolute">
          <form
            onSubmit={getMessages}
            className="w-full h-[60px] border border-black/50 rounded-md flex items-center"
          >
            <input
              className="border-none outline-none rounded-md w-full h-[55px] pl-[15px] pr-[18px] text-base"
              type="text"
              placeholder="Tell me what to do..."
              disabled=""
              name="sendMessage"
            />
            <button type="submit">
              <img src={SEND} alt="send" className="pr-2 cursor-pointer" />
            </button>
          </form>
        </div> */}
        <div
          className="flex justify-center items-center text-[#555555] "
          style={{ height: "calc(82% - 70px)" }}
        >
          Coming Soon!
        </div>
      </div>
    </>
  );
};

export default AIChat;
