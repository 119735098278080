import React from "react";
import Organization from "./Organization";

import { EDIT, ORGANIZATION } from "../../images";

const General = () => {
  return (
    <Organization>
      <div className="grid grid-cols-4 gap-4 p-5">
        <div className="w-full h-[72vh] p-5 rounded-[10px] border border-[#A5A5A5]">
          <div className="flex justify-between items-center ">
            <div className="text-[#555555] text-[16px] font-semibold">
              Organization
            </div>
            <img src={EDIT} />
          </div>

          <img src={ORGANIZATION} height={70} width={70} className="mt-8" />

          <div className="mt-4 text-[#555555]">
            <p className=" text-[24px] font-semibold">Solve4U</p>
            <p className="text-[15px]">Tagline or slogan here</p>
          </div>

          <div className="mt-8 text-[#555555]">
            <p className="text-[14px]">About</p>
            <p className="text-[14px] pt-2.5">
              This is a brief introduction about the organization. Just for
              personal satisfaction for the team owner and members
            </p>
          </div>
        </div>
        <div className="col-span-3  w-full h-[72vh] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5]">
          <div className="p-2">
            <p className=" font-semibold text-[16px] text-[#696969]">Members</p>

            <div className="grid grid-cols-3 gap-4 mt-2">
              <div className="flex gap-[10px]">
                <div className="w-[362px] h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    Tasks In Progress
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>

              <div className="flex  gap-[10px]">
                <div className="w-[362px] h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    Tasks In Progress
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>
              <div className="flex gap-[10px]">
                <div className="w-[362px] h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    All Tasks
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <p className=" font-semibold text-[16px] text-[#696969]">Project</p>

            <div className="grid grid-cols-3 gap-4 mt-2">
              <div className="flex gap-[10px]">
                <div className="w-[362px] h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    Tasks In Progress
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>

              <div className="flex  gap-[10px]">
                <div className="w-[362px] h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    Tasks Completed
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>
              <div className="flex gap-[10px]">
                <div className="w-[362px] h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    All Tasks
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <p className=" font-semibold text-[16px] text-[#696969]">Project</p>

            <div className="grid grid-cols-3 gap-4 mt-2">
              <div className="flex gap-[10px]">
                <div className="w-[362px] h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    Tasks In Progress
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>

              <div className="flex  gap-[10px]">
                <div className="w-[362px] h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    Tasks Completed
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>
              <div className="flex gap-[10px]">
                <div className="w-[362px] h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    All Tasks
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Organization>
  );
};

export default General;
