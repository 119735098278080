import React, { useEffect, useRef, useState } from "react";
import { CHECK, CLOSE, LINK, SOURCE, TOGGLE } from "../../images";
import { getFirstLetter } from "../../components/Formater";
import moment from "moment";
import { toast } from "react-toastify";
import PostRequest from "../../service/PostRequest";
import { Icon } from "@iconify/react/dist/iconify.js";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div
              className="relative w-[700px] bg-white rounded-[10px] overflow-hidden"
              ref={modalRef}
            >
              <div className="p-6">{children}</div>
              <button
                className="absolute top-0 right-0 m-4 mt-[25px] text-gray-500 hover:text-gray-600"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Post = (props) => {
  const { projectStory, getProjectStory } = props;

  // console.log("projectStory", projectStory);

  const [streamStatus, setStreamStatus] = useState(projectStory.status);
  const [isOpen, setIsOpen] = useState(projectStory.status);

  const [isToggle, setIsToggle] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const openMenu = () => {
    setIsToggle(!isToggle);
    setIsOpen(false);
  };

  const updateStream = async (status) => {
    if (window.confirm(`Are you sure you want to ${status}?`)) {
      const data = {
        id: projectStory._id,
        status: status,
      };

      try {
        const response = await PostRequest("/stream/update", data);
        if (response) {
          setStreamStatus(status);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const updateProjectStory = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    object.story = projectStory._id;
    console.log(object);

    try {
      const response = await PostRequest("/project/stories/update", object);

      if (response) {
        toast.success("Project story successfully!");
        getProjectStory();
        setIsOpen(false);
        setIsToggle(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const deleteProjectStories = async () => {
    if (window.confirm("Are you sure delete this project story?")) {
      try {
        const response = await PostRequest("/project/stories/delete", {
          storyId: projectStory._id,
        });
        if (response) {
          toast.success("Project story deleted successfully!");
          getProjectStory();
          setIsToggle(false);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
      <div className="p-6 m-auto">
        <div className="pt-4 px-3 pb-3 rounded-xl bg-[#F5F5F5] border border-[#E6E6E6]">
          <div className="flex justify-between items-center mb-4">
            <div className="flex gap-3 items-center">
              <div className="w-10 h-10 rounded-full bg-violet-500 flex items-center justify-center">
                <p className="font-bold text-white text-xl">
                  {getFirstLetter(projectStory.member.name)}
                </p>
              </div>
              <div>
                <h5 className="font-semibold">{projectStory.member.name}</h5>
                {projectStory.member.roles?.map((role, index) => (
                  <span key={index} className="text-[14px] text-[#555555]">
                    {role.name}
                  </span>
                ))}
              </div>

              <div className="text-[16px] text-[#555555] items-center ms-9">
                Type:{projectStory.type}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <p className="text-[#555555]">
                {moment(projectStory.createdAt).format(
                  "DD MMM, YYYY - hh:mm A"
                )}
              </p>
              {streamStatus == "Pending" && (
                <div className="flex gap-4 items-center">
                  <img
                    src={CLOSE}
                    alt="close"
                    onClick={() => updateStream("Rejected")}
                    className="h-10 w-10 p-[10px]"
                  />
                  <img
                    src={CHECK}
                    alt="check"
                    className="h-10 w-10"
                    onClick={() => updateStream("Approved")}
                  />
                </div>
              )}
              <button onClick={openMenu}>
                <div style={{ position: "relative" }}>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center gap-x-1.5 
                    rounded-m px-3 py-2 text-sm font-semibold text-gray-900"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                  >
                    <Icon icon="charm:menu-kebab" color="gray" fontSize={20} />
                  </button>
                </div>
              </button>

              {isToggle && (
                <div className="absolute ms-[220px]">
                  <div className="relative inline-block text-left">
                    <div
                      className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md
                       bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabindex="-1"
                    >
                      <div className="px-2" role="none">
                        <button
                          className="flex gap-3 items-center"
                          onClick={deleteProjectStories}
                        >
                          <Icon icon="uiw:delete" color="red" fontSize={16} />
                          <span
                            className="block py-2 text-[16px] text-red-600 font-medium"
                            role="menuitem"
                            tabindex="-1"
                            id="menu-item-0"
                          >
                            Delete
                          </span>
                        </button>
                        <button
                          className="flex gap-3 items-center"
                          onClick={toggleModal}
                        >
                          <Icon
                            icon="mage:edit"
                            color="[#555555]"
                            fontSize={18}
                          />
                          <span
                            className="block py-2  text-[16px] text-[#555555] font-medium"
                            role="menuitem"
                            tabindex="-1"
                            id="menu-item-1"
                          >
                            Edit
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <Modal
                    className="w-[500px] h-auto"
                    isOpen={isOpen}
                    onClose={toggleModal}
                  >
                    <form onSubmit={updateProjectStory}>
                      <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
                        Update To Stream
                      </h1>
                      <div className="text-sm">Post Heading</div>
                      <input
                        defaultValue={projectStory.title}
                        type="text"
                        placeholder="Your post heading..."
                        name="title"
                        className="mb-3 mt-2 border border-gray-300 w-full rounded px-3 py-2
                        placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      />
                      {/* <div className="text-sm mb-2">Stream Types</div>
                      <div className="w-full mb-2 flex justify-between p-1 border border-gray-300 rounded">
                        <select className="w-full">
                          <option>Post</option>
                          <option>Email</option>
                          <option>Transcript</option>
                          <option>Document</option>
                        </select>
                      </div> */}
                      <div className="text-sm mb-2">Post Details</div>
                      <textarea
                        defaultValue={projectStory.description}
                        name="description"
                        className="p-3 w-[652px] h-[252px] border border-gray-300 rounded placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      >
                        {/* Describe your post here... */}
                      </textarea>

                      <div className="flex justify-between items-center mt-3">
                        <div
                          className="flex items-center gap-[10px] text-base text-[#555555]
                        p-2 cursor-pointer rounded-md hover:bg-[#f5f5f5]"
                        >
                          <Icon icon="basil:attach-outline" fontSize={23} />
                          Attach Files
                        </div>
                        <div className="flex items-center text-base text-[#555555] gap-[10px]">
                          <div
                            className="cursor-pointer"
                            onClick={() => setIsOpen(false)}
                          >
                            <div className="p-2 rounded-md hover:bg-[#f5f5f5]">
                              Cancel
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="bg-[#2387FF] flex items-center gap-2 hover:bg-[#4F9FFF] text-white rounded-md px-[16px] py-2"
                          >
                            {/* {loaderbtn && (
                             <Loader color="white" height="20px" width="20px" />
                          )} */}
                            Update To Stream
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              )}
            </div>
          </div>

          <div className="border border-[#D7D7D7] rounded-[10px] p-5 bg-white">
            {/* <div className='max-w-[650px]'>
                            <h4 className='text-[24px] font-semibold text-[#191919]'>Fireflies meeting transcript</h4>
                            <p className='text-[16px] text-[#555555] my-4 tracking-[0.03em]'>This is dummy text here. This is only for test. Do not read this. This is dummy text here. This is only for test. Do not read this. This is dummy text here. This is only for test. Do not read this...</p>

                            <h5 className='text-[16px] font-semibold text-[#191919]'>Our goals:</h5>
                            <div className='text-[16px] text-[#555555] my-4 tracking-[0.03em]'>
                                <p>1. This is a goal generated from meeting.</p>
                                <p>2. Another goal generated from the meeting with client.</p>
                                <p>3. New idea and goals for the future.</p>
                                <p>4. Another goal from the meeting.</p>
                                <p>5. This is the last goal.</p>
                            </div>

                            <h5 className='text-[16px] font-semibold text-[#191919]'>Summary:</h5>
                            <p className='text-[16px] text-[#555555] mt-4 mb-6 tracking-[0.03em]'>This is dummy text here. This is only for test. Do not read this. This is dummy text here. This is only for test. Do not read this. This is dummy text here. This is only for test. Do not read this...</p>
                        </div>

                        <hr />

                        <div className=' border-t-[#B9B9B9] pt-4'>
                            <h5 className='font-semibold text-[#191919] mb-4'>Attachments (2)</h5>
                            <div className='flex gap-3'>
                                <p className='flex items-center gap-2 border border-[#878787] w-fit py-2 px-3 rounded-md'>
                                    <img src={SOURCE} alt="source" />
                                    <span className='font-semibold text-[#555555]'>Meeting (date).doc</span>
                                </p>
                                <p className='flex items-center gap-2 border border-[#878787] w-fit py-2 px-3 rounded-md'>
                                    <img src={SOURCE} alt="source" />
                                    <span className='font-semibold text-[#555555]'>Meeting.pdf</span>
                                </p>
                            </div>

                        </div> */}

            <h5 className="text-[16px] font-semibold text-[#191919]">
              {projectStory.title}
            </h5>

            <div
              className="story-description mb-5"
              dangerouslySetInnerHTML={{
                __html: projectStory.description,
              }}
            ></div>
            <hr></hr>
            <div className="flex gap-3 text-[18px] text-[#555555]">
              <div className="mt-4 flex items-center w-[281px] px-4 h-10 border border-[#878787] rounded-md ">
                <img src={LINK} />
                <span className="font-semibold ps-2">
                  Meeting (
                  {moment(projectStory?.updatedAt).format("DD MMM, YYYY")})
                </span>
              </div>
              <div className="mt-4 flex items-center w-[178px] px-4 h-10 border border-[#878787] rounded-md ">
                <img src={SOURCE} />
                <span className="font-semibold ps-2">Meeting.pdf</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;
