import React, { useState } from "react";
import Tasks from "./Tasks";
import { useSelector } from "react-redux";
import MarkdownAiResponse from "../../components/MarkdownAiResponse/MarkdownAiResponse";

const GeneralList = () => {
  const _tasksDetails = useSelector((state) => state.dataReducer.tasksDetails);
  const [isStatus, setIsStatus] = useState("inprogressTasks");
  // console.log("_tasksDetails", _tasksDetails);

  return (
    <>
      <Tasks isStatus={isStatus} setIsStatus={setIsStatus}>
        <div className="p-[24px] tracking-[0.03em]  h-[calc(100vh-212px)] overflow-y-auto overflow-hidden">
          {/* <div className="max-w-[650px] h-[calc(100vh-212px)] overflow-y-auto overflow-hidden">
            <h4 className="text-xl font-medium text-[#191919]">
              Ongoing Tasks
            </h4>
            <p className="text-[#555555] my-5">
              This is dummy text here. This is only for test. Do not read this.
              This is dummy text here. This is only for test. Do not read this.
              This is dummy text here. This is only for test. Do not read
              this...
            </p>

            <h5 className="font-medium text-[#191919] mb-2">Todays Tasks:</h5>
            <p className="text-[#555555] mb-1">
              1. This is a task generated from meeting. <br /> This is the
              description of this task. This is the description of this task.
              This is the description of this task.
            </p>
            <p className="text-[#555555] mb-1">
              2. Another task generated from the meeting with client.This is the
              description of this task. This is the description of this task.
              This is the description of this task. This is the description of
              this task. This is the description of this task. This is the
              description of this task.
            </p>
            <p className="text-[#555555] mb-1">
              New task uploaded by project manager.
            </p>
            <p className="text-[#555555] mb-1">
              Another task from the meeting.This is the description of this
              task. This is the description of this task.
            </p>
          </div> */}
          <div className="ai-response">
            <div className=" text-[#555555] my-4 tracking-[0.03em] max-w-[650px]">
              <div
                className=""
                // dangerouslySetInnerHTML={{ __html: _tasksDetails[isStatus] }}
              >
                <MarkdownAiResponse
                  markdownAiResponse={_tasksDetails[isStatus]}
                />
              </div>
            </div>
          </div>
        </div>
      </Tasks>
    </>
  );
};

export default GeneralList;
