import React, { Children, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ADD, EDIT, HISTORY, REFRESH1 } from "../../images";
import Default from "../../components/theme/Default/Default";
import AIChat from "../../components/AIChat";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import MarkdownAiResponse from "../../components/MarkdownAiResponse/MarkdownAiResponse";
import PostRequest from "../../service/PostRequest";
import "../../App.css";
import {
  detailsRefresh,
  getNewLakes,
  promptHistory,
} from "../../store/data.action";
import { toast } from "react-toastify";
import Settings from "./Settings";
import CreateNewLake from "./CreateNewLake";
import { FaPlus } from "react-icons/fa";
import socket from "../../service/socket";

const Lakes = ({ children }) => {
  const navigate = useNavigate();

  const [openCreateLake, setOpenCreateLake] = useState(false);
  const [isOpenNewLakeModel, setIsOpenNewLakeModel] = useState(false);
  const [loader, setLoader] = useState(false);
  const [menuHide, setMenuHide] = useState(false);

  const [settingsPromptModel, setSettingsPromptModel] = useState(false);
  const [saveLake, setSaveLake] = useState(false);

  //   Active Lake
  const [activeLake, setActiveLake] = useState({});

  const [activeIndexToday, setActiveIndexToday] = useState(0);
  const [activeIndexHistory, setActiveIndexHistory] = useState(null);

  const dispatch = useDispatch();

  const params = useParams();

  const _getLakes = useSelector((state) => state.dataReducer.getLakes);
  const _promptHistory = useSelector(
    (state) => state.dataReducer.promptHistory
  );
  const _detailsRefresh = useSelector(
    (state) => state.dataReducer.detailsRefreshLoader
  );

  const organization_id = params.organization_id;
  const project_id = params.project_id;
  const source = params.source;

  const openNewLakeModel = () => {
    setOpenCreateLake(!openCreateLake);
    setMenuHide(false);
  };

  const viewUpdateHistory = () => {
    setMenuHide(true);
    setOpenCreateLake(false);
  };

  const closeLakeHistory = (userClick) => {
    setMenuHide(false);
    setOpenCreateLake(false);

    if (userClick) {
      // const today = _promptHistory.today;

      // if (today && Array.isArray(today)) {
      if (_promptHistory.today) {
        const firstIndexData = _promptHistory.today[0];
        // console.log(
        //   "First Index Data...",
        //   firstIndexData.lakes[activeLake?._id]
        // );
        setActiveLake({
          ...activeLake,
          description: firstIndexData?.lakes[activeLake._id],
        });
        setActiveIndexToday(0);
        setActiveIndexHistory(null);
      } else {
        console.log("Today is not an array or is undefined");
      }
    }
  };

  const settingPrompt = () => {
    setSettingsPromptModel(!settingsPromptModel);
    setMenuHide(false);
    // setOpenCreateLake(false);
    // setActiveLake("");
  };

  const refreshNewPrompt = async () => {
    const data = {
      lake: activeLake?._id,
      project: project_id,
    };

    setLoader(true);
    try {
      const refreshPrompt = await PostRequest("/details/refresh", data);
      setActiveLake(refreshPrompt);
      dispatch(promptHistory({ project: project_id, lake: source }));
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleOnSelectHistory = (history, index) => {
    if (activeLake && history.lakes[activeLake._id]) {
      setActiveLake({
        ...activeLake,
        description: history.lakes[activeLake._id],
      });
      setActiveIndexHistory(index);
      setActiveIndexToday(null);
    }
  };

  const handleOnTodayHistory = (today, index) => {
    if (activeLake && today.lakes[activeLake._id]) {
      setActiveLake({
        ...activeLake,
        description: today.lakes[activeLake._id],
      });
      setActiveIndexToday(index);
      setActiveIndexHistory(null);
    }
  };

  const toggleOpen = (id) => {
    if (openCreateLake) {
      return true;
    }

    if (activeLake?._id != id && (settingsPromptModel || menuHide)) {
      return true;
    }

    return false;
  };

  const newLakeModel = () => {
    if (settingsPromptModel || menuHide) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (params.source == "create-lake") {
      setOpenCreateLake(true);
      return;
    }

    if (params.source == "INIT" && _getLakes.length != 0) {
      if (_getLakes[0].project == params.project_id) {
        var url = window.location.pathname;
        url = url.replace("INIT", _getLakes[0]._id);
        navigate(url);
        setOpenCreateLake(false);
      }
    } else {
      const findLake = _getLakes.find((lake) => lake._id == params.source);
      setActiveLake(findLake);
    }

    // JOIN ROOM
    socket.emit("JOIN_LAKE", params.source);

    setSettingsPromptModel(false);
    closeLakeHistory();
  }, [params.source, _getLakes]);

  useEffect(() => {
    socket.on("ON_LAKE", (data) => {
      dispatch(getNewLakes({ project: data.project }));
    });
  }, []);

  useEffect(() => {
    // promptHistory();
    dispatch(promptHistory({ project: project_id, lake: source }));
  }, [params.source]);

  return (
    <>
      <Default>
        <div className="flex">
          <div className="w-full">
            <div className="px-6 py-4 border-b border-gray-300 flex items-center justify-between text-[#555555] font-semibold text-base">
              <div className="flex items-center gap-1 text-[#555555]">
                {/* {newLake && */}
                {_getLakes.map((lake, index) => (
                  <NavLink
                    key={index}
                    to={`/${organization_id}/${project_id}/project-details/${lake._id}`}
                    className={`px-4 py-2 rounded-md  hover:bg-[#F5F5F5] cursor-default`}
                  >
                    <button
                      disabled={toggleOpen(lake._id)}
                      className={`${
                        toggleOpen(lake._id)
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer"
                      } `}
                    >
                      {lake.title}
                    </button>
                  </NavLink>
                ))}

                <NavLink
                  to={`/${organization_id}/${project_id}/project-details/create-lake`}
                  className={`p-3 flex items-center justify-between rounded-md 
                     hover:bg-[#F5F5F5]  h-[40px] w-[40px] cursor-default`}
                >
                  <button
                    onClick={openNewLakeModel}
                    disabled={newLakeModel()}
                    className={`${
                      newLakeModel()
                        ? "opacity-50 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                  >
                    <FaPlus size={14} />
                  </button>
                </NavLink>
              </div>
            </div>

            <div>
              {!settingsPromptModel && (
                <div>
                  {!openCreateLake && (
                    <div
                      className="p-6 border-b border-gray-300 flex items-center 
                        justify-between text-[#555555] font-semibold text-base"
                    >
                      {menuHide ? (
                        <button
                          className="flex items-center gap-3  text-black 
                          text-[16px]"
                        >
                          {/* <img src={ARROW_BACK} width={12} height={12} /> */}
                          <div className="cursor-pointer p-2 rounded-md hover:bg-[#f5f5f5]">
                            <Icon
                              icon="uiw:left"
                              fontSize={22}
                              onClick={() => closeLakeHistory(true)}
                            />
                          </div>
                          <div className="cursor-text">Lake History</div>
                        </button>
                      ) : (
                        <button
                          className="flex items-center gap-1.5 p-2 rounded-md text-[#555555] 
                          hover:bg-[#f5f5f5] cursor-pointer text-[16px]"
                          onClick={viewUpdateHistory}
                        >
                          <img src={HISTORY} width={22} height={22} />
                          View History
                          {/* {moment(new Date()).format("DD MMM, YYYY ") + "(Today)"} */}
                        </button>
                      )}

                      {!menuHide && (
                        <div className="flex  items-center gap-7 text-[#555555] cursor-pointer">
                          {/* {_getLakes?.isUpdatingByAI || _detailsRefresh ? ( */}
                          {loader || activeLake?.isUpdating ? (
                            <div>Updating...</div>
                          ) : (
                            <p>
                              Updated:
                              {moment(activeLake?.updatedAt).format(
                                "DD MMM, YYYY [at] hh:mm A"
                              )}
                            </p>
                          )}
                        </div>
                      )}

                      {menuHide ? (
                        <div className="ms-auto flex items-center gap-3">
                          <button
                            className="p-2 cursor-pointer rounded-md text-[16px] hover:bg-[#f5f5f5]"
                            onClick={() => setMenuHide(false)}
                          >
                            Cancel
                          </button>
                          <button className="p-2 cursor-pointer rounded-md text-[16px] hover:bg-[#f5f5f5]">
                            Delete
                          </button>
                          <button
                            className="cursor-pointer flex items-center gap-2
                        hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2"
                          >
                            Restore History
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center gap-5">
                          <button
                            className="flex gap-2 p-2 cursor-pointer rounded-md text-[16px] hover:bg-[#f5f5f5]"
                            onClick={settingPrompt}
                          >
                            <img
                              src={EDIT}
                              alt="edit"
                              className="w-[24px] h-[24px] cursor-pointer"
                            />
                            <p className="cursor-pointer">Settings</p>
                          </button>

                          <button
                            className="cursor-pointer flex items-center gap-2.5
                             hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-[18px] py-[9px]"
                            onClick={refreshNewPrompt}
                          >
                            <img
                              src={REFRESH1}
                              alt="edit"
                              className=" cursor-pointe"
                            />
                            Refresh
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            {menuHide && (
              //   <div className="p-3 border-r border-gray-300">
              <div className={`${menuHide ? "flex" : ""} h-full`}>
                <div className="p-3 border-r border-gray-300">
                  <div className="w-[280px] h-[618px] overflow-y-auto overflow-hidden">
                    <div className="mt-2">
                      <div className="text-[#878787] text-[14px] font-bold">
                        Today
                      </div>
                      <div className="mt-2 ">
                        {/* {moment().format("DD MMM, YYYY - hh:mm A")} */}

                        {_promptHistory.today?.map((today, index) => (
                          <div
                            key={index}
                            onClick={() => handleOnTodayHistory(today, index)}
                            className={`${
                              index === activeIndexToday && !activeIndexHistory
                                ? "bg-[#D3E7FF] cursor-pointer text-[14px] rounded-md px-3 py-2.5 text-black font-semibold"
                                : " px-3 py-2.5 text-[#878787] text-[14px] font-bold cursor-pointer"
                            } `}
                          >
                            {moment(today.createdAt).format(
                              "DD MMM, YYYY - hh:mm A"
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mt-8">
                      <div className="text-[#878787] text-[14px] font-bold">
                        Other History
                      </div>
                      <div className="">
                        {_promptHistory.history?.map((item, index) => (
                          <div key={index} className="mt-2.5">
                            <div
                              onClick={() =>
                                handleOnSelectHistory(item.data, index)
                              }
                              className={`${
                                index === activeIndexHistory &&
                                !activeIndexToday
                                  ? "bg-[#D3E7FF] cursor-pointer text-[14px] rounded-md px-3 py-2.5 text-black font-semibold"
                                  : " px-3 py-2.5 text-[#878787] text-[14px] font-bold cursor-pointer"
                              } `}
                            >
                              {moment(item.data.createdAt).format(
                                "DD MMM,YYYY"
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* {promptHistory.map((date, index) => (
                      <div
                        key={index}
                        className={`${
                          index === activeIndex
                            ? "active-date cursor-pointer text-[14px] rounded-md px-3 py-2.5 text-black font-semibold"
                            : "text-[#555555] text-[14px] cursor-pointer font-semibold rounded-md px-3 py-2.5"
                        } mb-2 mt-2`}
                        onClick={() => displayHistory(date, index)}
                      >
                        {moment(date.createdAt).format("DD MMM, YYYY")}
                      </div>
                    ))} */}
                    </div>
                  </div>
                </div>

                <div className="p-6 w-full overflow-y-auto overflow-hidden h-[calc(100vh-218px)]">
                  <div className=" text-[#555555] mb-2 tracking-[0.03em] ai-response">
                    <MarkdownAiResponse
                      markdownAiResponse={activeLake?.description}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Create New Lake */}
            <div>
              {openCreateLake && (
                <CreateNewLake
                  openCreateLake={openCreateLake}
                  setOpenCreateLake={setOpenCreateLake}
                  params={params}
                />
              )}

              {!menuHide && (
                <div className="">
                  <div className=" text-[#555555] mb-2 tracking-[0.03em] ai-response">
                    {loader ? (
                      <div className="flex justify-center mt-5">
                        <Loader height="40px" width="40px" />
                      </div>
                    ) : (
                      !settingsPromptModel && (
                        <div className="p-6 w-full overflow-y-auto overflow-hidden h-[calc(100vh-218px)]">
                          <MarkdownAiResponse
                            markdownAiResponse={activeLake?.description}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Settings */}
            {settingsPromptModel && (
              <>
                <div>
                  <Settings
                    setSettingsPromptModel={setSettingsPromptModel}
                    settingsPromptModel={settingsPromptModel}
                    activeLake={activeLake}
                  />
                </div>
              </>
            )}
          </div>

          <div className="w-[550px] customGray">
            <AIChat />
          </div>
        </div>
      </Default>
    </>
  );
};

export default Lakes;
