import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getNewLakes,
  loadGetMembers,
  loadGetProjects,
  loadProjectDetails,
  loadTasksDetails,
} from "../../store/data.action";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const DataLoader = () => {
  const isAuth = window.localStorage.getItem("token");
  const dispatch = useDispatch();
  const params = useParams();

  const getMembers = () => {
    if (isAuth) {
      try {
        dispatch(loadGetMembers());
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const getProjects = () => {
    if (isAuth) {
      try {
        dispatch(loadGetProjects(params));
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const getProjectDetails = () => {
    if (isAuth) {
      try {
        const storedProject = params.project_id;

        if (storedProject) {
          dispatch(loadProjectDetails({ project: storedProject }));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };
  const getLakes = () => {
    if (isAuth) {
      try {
        const storedProject = params.project_id;

        if (storedProject) {
          dispatch(getNewLakes({ project: storedProject }));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const getTasksDetails = () => {
    if (isAuth) {
      try {
        const storedProject = params.project_id;

        if (storedProject) {
          dispatch(loadTasksDetails({ project: storedProject }));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    getProjects();
  }, [isAuth, params.organization_id]);

  useEffect(() => {
    // getProjectDetails();
    getLakes();
    // console.log("params", params);
  }, [params.project_id]);

  // useEffect(() => )

  useEffect(() => {
    getTasksDetails();
  }, [params.project_id]);

  return <></>;
};

export default DataLoader;
