import React, { useState } from "react";
import PostRequest from "../../service/PostRequest";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const Register = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const registerData = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    // console.log("register", object);

    setLoader(true);

    try {
      const response = await PostRequest("/member/register", object);

      if (response) {
        toast.success("Account created");

        window.localStorage.setItem("token", response.token);
        window.localStorage.setItem("userName", response.name);
        window.localStorage.setItem("organization", response.organization);

        if (response.activeProject) {
          navigate(
            `/${response.organization}/${response.activeProject}/project-details/updates`
          );
        } else {
          navigate(`/${response.organization}/organization/general`);
        }
      }
    } catch (error) {
      toast.error(error);
    }

    setLoader(false);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="bgImage"></div>
      <div className="min-h-screen flex items-center justify-start bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="ps-8">
          {/* <div className="max-w-md w-full bg-white border border-gray-200 rounded-lg shadow-md p-8"> */}
          <div>
            <h2 className="text-start text-2xl font-extrabold text-gray-900">
              Sign Up
            </h2>
          </div>
          <form className="mt-4" onSubmit={registerData}>
            <div className="shadow-sm">
              {/* <div className="mb-3">
                <input
                  required
                  name="organization"
                  type="text"
                  className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Organization name"
                />
              </div> */}

              <div className="mb-3">
                <input
                  required
                  name="name"
                  type="name"
                  className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Name"
                />
              </div>

              <div className="mb-3">
                <input
                  required
                  name="phoneNumber"
                  type="text"
                  className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Phone number"
                />
              </div>

              <div className="mb-3">
                <input
                  required
                  name="email"
                  type="email"
                  className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email Address"
                />
              </div>

              <div>
                <input
                  required
                  name="password"
                  type="password"
                  className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="mt-5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loader && <Loader color="white" height="18px" width="18px" />}
                &nbsp;&nbsp; Create account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
