import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { getFirstLetter } from '../../Formater'
import { toast } from 'react-toastify'

const Header = () => {
    const userName = window.localStorage.getItem('userName')

    return (
        <>
            <div className='h-[72px] w-full'>

                <div className='flex justify-between items-center py-3 px-6'>
                    <div className='flex justify-between items-center w-[450px] h-10 rounded-md border border-[#878787] px-3 text-[16px] placeholder:text-[#696969] focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 bg-[#F5F5F5]'>
                        <input
                            className="border-none outline-none w-full pl-[10px] pr-[18px] bg-[#F5F5F5] text-[16px]"
                            type="text"
                            placeholder="Search..."
                            disabled=""
                        />
                        <Icon icon="material-symbols-light:search-rounded" className='size-6 text-slate-500' />
                    </div>

                    <div className='d-flex'>

                        <div className='w-10 h-10 rounded-full bg-yellow-400 flex items-center justify-center'>
                            <p className='font-bold text-white text-xl'>{getFirstLetter(userName)}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Header