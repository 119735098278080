import Default from "../../components/theme/Default/Default";
import { useNavigate, useParams } from "react-router-dom";
import OrganizationMenu from "../../components/Organization/OrganizationMenu";

const Organization = (props) => {
  const navigator = useNavigate();

  const params = useParams();
  // console.log(params);

  const organizationId = params["organization_id"];
  const projectId = params["project_id"];
  // const organizationType = params["general"];

  const isAuth = window.localStorage.getItem("token");

  if (!isAuth) {
    navigator("/login");
  }
  // console.log("Organization");

  return (
    <Default>
      <div className="w-full">
        <OrganizationMenu
          organizationId={organizationId}
          projectId={projectId}
        />

        {props.children}
      </div>
    </Default>
  );
};

export default Organization;
