import React, { useState } from "react";
import Tasks from "./Tasks";
import { useSelector } from "react-redux";
import Status from "../../components/Tasks/Status";
import MarkdownAiResponse from "../../components/MarkdownAiResponse/MarkdownAiResponse";

const Members = () => {
  const _tasksDetails = useSelector((state) => state.dataReducer.tasksDetails);
  const [isStatus, setIsStatus] = useState("inProgressTasks");

  return (
    <>
      <Tasks isStatus={isStatus} setIsStatus={setIsStatus}>
        {/* <div className="p-[24px] tracking-[0.03em]">
          <div className="max-w-[650px]">
            <div className="text-[#555555] my-4 tracking-[0.03em]">
              {_tasksDetails?.notStaredTasks}
            </div>
          </div>
        </div> */}
        {/* md:w-3/12 md:w-9/12 flex-col md:flex-row */}
        <div className="flex ">
          <div className="w-[380px] h-[calc(100vh-212px)]   border-r border-gray-300 px-3 py-5">
            <div className="flex items-center gap-3 px-3 py-2.5">
              <div className="w-9 h-9 rounded-full bg-gray-300 flex items-center justify-center">
                <p className="font-bold text-white text-xl">
                  {/* {getFirstLetter(projectStory.member.name)} */}
                </p>
              </div>
              <p className="text-[#555555] font-semibold text-base">
                Unassigned Tasks
              </p>
            </div>

            <div className="mt-8">
              <p className="text-[#878787] text-[14px] font-semibold">
                Active Members
              </p>

              <div className="active rounded-md px-3 py-2.5 mt-3 mb-2">
                <div className="flex gap-3 mt-2">
                  <div className="w-9 h-9 rounded-full bg-violet-500 flex items-center justify-center">
                    <p className="font-bold text-white text-xl">
                      {/* {getFirstLetter(projectStory.member.name)} */}W
                    </p>
                  </div>
                  <div>
                    <p className="text-black font-semibold text-[15px] leading-tight">
                      Will Kay
                    </p>
                    <p className="text-[#155199] text-[12px]  font-normal   ">
                      {/* <p className="text-[#555555] text-[12px]  font-normal   "> */}
                      Project Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <p className="text-[#878787] text-[14px] font-semibold">
                Inactive Members
              </p>

              <div className="px-3 py-2.5">
                <div className="flex gap-3 mt-2">
                  <div className="w-9 h-9 rounded-full bg-gray-300  flex items-center justify-center">
                    <p className="font-bold text-white text-xl">
                      {/* {getFirstLetter(projectStory.member.name)} */}J
                    </p>
                  </div>
                  <div>
                    <p className="text-[#555555] font-semibold text-[15px] leading-tight">
                      Joe Random Guy
                    </p>
                    <p className="text-[#555555] text-[12px]  font-normal ">
                      Designer, Developer ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-6 h-[calc(100vh-212px)] overflow-y-auto overflow-hidden">
            <div className="rounded-xl px-6 py-5 border border-[#E6E6E6] mb-[24px]">
              {/* <h4 className="text-[16px] font-medium text-[#191919]">
                (Today) Date - 21 March, 2024:
              </h4>
              <div className="my-5">
                <p className="text-[#555555] mb-1">
                  1. This is a task generated from meeting. <br /> This is the
                  description of this task. This is the description of this
                  task. This is the description of this task.
                </p>
                <p className="text-[#555555] mb-1">
                  2. Another task generated from the meeting with client.This is
                  the description of this task. This is the description of this
                  task. This is the description of this task. This is the
                  description of this task. This is the description of this
                  task. This is the description of this task.
                </p>
                <p className="text-[#555555] mb-1">
                  New task uploaded by project manager.
                </p>
                <p className="text-[#555555] mb-1">
                  Another task from the meeting.This is the description of this
                  task. This is the description of this task.
                </p>
              </div> */}

              <div
                className="text-[#555555] ai-response"
                // dangerouslySetInnerHTML={{ __html: _tasksDetails[isStatus] }}
              >
                <MarkdownAiResponse
                  markdownAiResponse={_tasksDetails[isStatus]}
                />
              </div>
            </div>
          </div>
        </div>
      </Tasks>
    </>
  );
};

export default Members;
