// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   // <React.StrictMode>
//   <App />
//   // </React.StrictMode>
// );

// reportWebVitals();

import "./index.css";

import * as React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

// const showErrorOverlay = (err) => {
//   const ErrorOverlay = customElements.get("vite-error-overlay");
//   if (!ErrorOverlay) {
//     return;
//   }
//   const overlay = new ErrorOverlay(err);
//   const body = document.body;
//   if (body !== null) {
//     body.appendChild(overlay);
//   }
// };

// window.addEventListener("error", showErrorOverlay);
// window.addEventListener("unhandledrejection", ({ reason }) =>
//   showErrorOverlay(reason)
// );

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
