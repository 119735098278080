import { type } from "@testing-library/user-event/dist/type";
import PostRequest from "../service/PostRequest";
import { toast } from "react-toastify";

export const PROJECT_DETAILS = "PROJECT_DETAILS";
export const TASKS_DETAILS = "TASKS_DETAILS";
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_PROJECTS = "GET_PROJECTS";
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const PROJECT_LOADER = "PROJECT_LOADER";
export const LOADER_PROMPT_HISTORY = "LOADER_PROMPT_HISTORY";
export const PROJECT_STORY_PENDING = "PROJECT_STORY_PENDING";
export const DETAILS_REFRESH = "DETAILS_REFRESH";
export const DETAILS_REFRESH_LOADER = "DETAILS_REFRESH_LOADER";
export const GET_LAKES = "GET_LAKES";
export const PROMPT_HISTORY = "PROMPT_HISTORY";

export const loadProjectDetails = (data = {}) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/project/details", data);

      dispatch({
        type: "PROJECT_DETAILS",
        value: response,
      });
    } catch (error) {
      toast.error(error);
    }
  };
};

export const loadTasksDetails = (data = {}) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/project/tasks", data);

      dispatch({
        type: "TASKS_DETAILS",
        value: response,
      });
    } catch (error) {
      toast.error(error);
    }
  };
};

export const loadGetMembers = (data = {}) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/members", data);

      if (response) {
        dispatch({
          type: "GET_MEMBERS",
          value: response,
        });
      }
    } catch (error) {
      // toast.error(error);
    }
  };
};

export const loadPromptHistory = (data = {}, isTasks = false) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/prompt/history", data);

      console.log("action");
      if (response) {
        dispatch({
          type: isTasks ? "TASKS_DETAILS" : "PROJECT_DETAILS",
          value: response,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
};

export const resetActiveProject = () => {
  return async (dispatch) => {};
};

export const loadGetProjects = (data = {}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "PROJECT_LOADER",
        value: true,
      });
      const response = await PostRequest("/projects", data);
      if (response) {
        // let activeProjectId = data.project_id;
        // console.log("activeProjectId", activeProjectId);
        // if (
        //   (response.length != 0 && !activeProjectId) ||
        //   activeProjectId === "undefined"
        // ) {
        //   activeProjectId = response[0]._id;
        //   window.localStorage.setItem("activeProjectName", response[0].name);
        // }
        // window.localStorage.setItem("projects", JSON.stringify(response));
        dispatch({
          type: "GET_PROJECTS",
          value: response,
        });
      }
    } catch (error) {
      toast.error(error);
    } finally {
      dispatch({
        type: "PROJECT_LOADER",
        value: false,
      });
    }
  };
};

export const projectStoryPending = (data) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/project/stories/pending", data);

      if (response) {
        dispatch({
          type: "PROJECT_STORY_PENDING",
          value: response,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
};
export const detailsRefresh = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "DETAILS_REFRESH_LOADER",
        value: true,
      });
      const response = await PostRequest("/details/refresh", data);

      if (response) {
        dispatch({
          type: "PROJECT_DETAILS",
          value: response,
        });
      }
    } catch (error) {
      toast.error(error);
    } finally {
      dispatch({
        type: "DETAILS_REFRESH_LOADER",
        value: false,
      });
    }
  };
};
export const getNewLakes = (data) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/lakes", data);

      if (response) {
        dispatch({
          type: "GET_LAKES",
          value: response,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
};

export const activePromptHistory = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "PROJECT_DETAILS",
      value: data,
    });
  };
};

export const promptHistory = (data) => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/prompt/history", data);

      if (response) {
        dispatch({
          type: "PROMPT_HISTORY",
          value: response,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
};
