import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef, useState } from "react";
import PostRequest from "../../service/PostRequest";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNewLakes, promptHistory } from "../../store/data.action";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const Settings = (props) => {
  const previewTextAreaRef = useRef();

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("General");
  const [settingSource, setSettingSource] = useState(
    props.activeLake && props.activeLake.resources.length > 0
      ? props.activeLake.resources
      : ["All Stream Entries"]
  );

  const { setSettingsPromptModel, settingsPromptModel } = props;

  const [updatedData, setUpdatedData] = useState(props.activeLake || {});
  const [lakeName, setLakeName] = useState(props.activeLake.title);
  const [updatedPrompt, setUpdatedPrompt] = useState(props.activeLake.prompt);
  // console.log("updatedData", updatedData);
  // console.log("lakeName", lakeName);
  // console.log("updatedPrompt", updatedPrompt);
  const [playgroundResponse, setPlaygroundResponse] = useState(
    props.activeLake ? props.activeLake.description : ""
  );

  const [reservoirs, setReservoirs] = useState([]);

  const _getLakes = useSelector((state) => state.dataReducer.getLakes);

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const handleSettingSources = (option) => {
    if (settingSource.includes(option)) {
      setSettingSource(settingSource.filter((item) => item !== option));
    } else {
      // let updatedSource = [...settingSource, option];
      // if (option === "All Stream Entries") {
      //   updatedSource = updatedSource.filter(
      //     (item) => item !== "Only New Stream Entries"
      //   );
      // } else if (option === "Only New Stream Entries") {
      //   updatedSource = updatedSource.filter(
      //     (item) => item !== "All Stream Entries"
      //   );
      // }
      setSettingSource([...settingSource, option]);
      // setSettingSource(updatedSource);
    }
  };

  const deleteLake = async () => {
    try {
      if (
        !window.confirm(
          "Are you sure you want to delete this lake? once its delete, it will deleted permanently."
        )
      )
        return false;

      const lakeDelete = await PostRequest("/delete/lake", {
        lake: params.source,
      });

      toast.success("Lake Deleted Successfully");
      dispatch(getNewLakes({ project: params.project_id }));
      // setOpenCreateLake(false);
      navigate(
        `/${params.organization_id}/${params.project_id}/project-details/INIT`
      );
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdateForm = (key, value) => {
    setUpdatedData((prev) => {
      console.log("prev", prev);
      setUpdatedData({ ...prev, [key]: value });
    });
  };

  // const isFormValid = () => {
  //   if (playgroundResponse) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const handleGeneratePlayground = async () => {
    if (!lakeName) {
      toast.error("Please enter lake name");
      handleActiveTab("General");
      return false;
    }
    if (!settingSource || settingSource.length == 0) {
      toast.error("Please select source");
      handleActiveTab("General");
      return false;
    }
    if (!updatedPrompt) {
      toast.error("Please enter prompt");
      return false;
    }
    setIsLoading(true);
    try {
      const data = {
        project: props.activeLake.project,
        prompt: updatedPrompt,
        sources: settingSource,
      };

      const response = await PostRequest("/prompt/preview", data);
      if (response) {
        handleActiveTab("Preview");
        previewTextAreaRef.current.value = response;
        setPlaygroundResponse(response);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const previewBtnDataValidation = () => {
    if (!lakeName) {
      toast.error("Enter lake name");
      return false;
    }
    if (!settingSource || settingSource.length == 0) {
      toast.error("Please select source");
      return false;
    }
    if (updatedData && settingSource.length > 0) {
      handleActiveTab("Prompt");
    }
  };

  const saveUpdatePrompt = async () => {
    if (!lakeName) {
      toast.error("Please enter lake name");
      handleActiveTab("General");
      return false;
    }
    if (!settingSource || settingSource.length == 0) {
      toast.error("Please select source");
      handleActiveTab("General");
      return false;
    }
    if (!updatedPrompt) {
      toast.error("Please enter prompt");
      handleActiveTab("Prompt");
      return false;
    }
    if (!playgroundResponse) {
      toast.error("Please enter Preview");
      return false;
    }

    setLoader(true);
    try {
      const data = {
        lake: props.activeLake._id,
        prompt: updatedPrompt,
        sources: settingSource,
        description: playgroundResponse,
        title: lakeName,
        project: props.activeLake.project,
      };

      const response = await PostRequest("/prompt/update", data);
      if (response) {
        toast.success("Lake Updates with new Changes!");
        dispatch(getNewLakes({ project: params.project_id }));
        dispatch(
          promptHistory({ project: params.project_id, lake: params.source })
        );
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setLoader(false);
    }
  };

  const getReservoir = async () => {
    try {
      const response = await PostRequest("/reservoirs", {
        project: params.project_id,
      });

      if (response) {
        setReservoirs(response);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getReservoir();
  }, []);

  return (
    <>
      {settingsPromptModel && (
        <div className=" border-b border-gray-300  text-[#555555] font-semibold text-base">
          <div className="pt-4 pl-6 pr-6 flex items-center justify-between">
            <button
              className="flex items-center gap-3 text-black 
                     text-[16px]"
            >
              <button className="cursor-pointer p-2 rounded-md hover:bg-[#f5f5f5]">
                <Icon
                  icon="uiw:left"
                  fontSize={22}
                  onClick={() => setSettingsPromptModel(false)}
                />
              </button>
              <div className="cursor-text">Lake Settings</div>
            </button>

            <div className="flex items-center gap-2.5">
              <button
                className="p-2 cursor-pointer rounded-md text-[16px] hover:bg-[#f5f5f5]"
                onClick={() => setSettingsPromptModel(false)}
              >
                Cancel
              </button>

              {/* <button
                className="cursor-pointer flex items-center gap-2
                         hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2"
                onClick={saveUpdatePrompt}
              >
                {isLoading && (
                  <Loader color="white" height="17px" width="17px" />
                )}
                Save Lake
              </button> */}

              {!["updates", "description", "goals"].includes(
                updatedData?.staticName
              ) && (
                <button
                  className="p-2 cursor-pointer rounded-md text-[16px] hover:bg-[#f5f5f5]"
                  onClick={deleteLake}
                >
                  Delete
                </button>
              )}

              <button
                // disabled={isFormValid()}
                // disabled={true}
                className="cursor-pointer flex items-center gap-2
                         hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2"
                onClick={saveUpdatePrompt}
              >
                {loader && <Loader color="white" height="17px" width="17px" />}
                Save Lake
              </button>
            </div>
          </div>

          <div className="mt-4 flex gap-5 pb-3 pl-6 pr-6">
            <button
              onClick={() => handleActiveTab("General")}
              className={`${
                activeTab == "General" && "text-[#155199] activeEditPrompt"
              } cursor-pointer`}
            >
              General
            </button>
            <button
              onClick={() => handleActiveTab("Prompt")}
              className={` ${
                activeTab == "Prompt" && "text-[#155199] activeEditPrompt"
              } cursor-pointer`}
            >
              Prompt
            </button>
            <button
              onClick={() => handleActiveTab("Preview")}
              className={`${
                activeTab == "Preview" && "text-[#155199] activeEditPrompt"
              } cursor-pointer`}
            >
              Preview
            </button>
          </div>
        </div>
      )}

      {/* General */}
      <div
        className="p-6 text-[#555555] overflow-y-auto overflow-hidden h-[600px]"
        style={{ display: activeTab == "General" ? "block" : "none" }}
      >
        <div className="bg-[#FFF2C2] rounded-md p-2.5 flex items-center gap-6">
          <Icon
            icon="heroicons:light-bulb"
            color="#191919"
            fontSize={24}
            className="ms-6"
          />
          <p className="w-[650px] leading-6 text-[15px] text-[#191919]">
            Ai takes Sources to get data for your prompt. So choose your lake
            sources wisely to get the absolute results you want.
          </p>
        </div>

        <div className="mt-6">
          <div className="text-sm mb-2">
            Lake Name
            <span className="text-pink-700 text-[16px] ps-1">*</span>
          </div>
          <input
            type="text"
            required
            placeholder="Lake Name"
            name="name"
            defaultValue={props.activeLake?.title}
            onChange={(e) => setLakeName(e.target.value)}
            className="mb-3 border border-gray-500 w-full rounded px-3 py-2 
              placeholder-font-normal  rounded-t-md 
                focus:outline-none focus:z-10 sm:text-sm"
          />

          <div className="text-sm mb-2">
            Sources
            <span className="text-pink-700 text-[16px] ps-1">*</span>
          </div>

          <div
            className="mb-3 border border-gray-500 w-full rounded
                  px-3 py-2 text-gray-900 rounded-t-md 
                    sm:text-sm"
          >
            <div>
              <select
                value={settingSource}
                onChange={(e) => handleSettingSources(e.target.value)}
                className="w-full text-gray-500"
              >
                <option value="" disabled hidden>
                  Select To Add Source
                </option>
                {/* <option value="Only New Stream Entries">
                  Only New Stream Entries
                  </option> */}
                <optgroup label="Lakes">
                  <option value="All Stream Entries">All Stream Entries</option>
                  {_getLakes.map((lake, index) => (
                    <option key={index} value={lake.title}>
                      {lake.title}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="Reservoirs">
                  {reservoirs.map((reservoir, i) => (
                    <option key={i} value={reservoir.title}>
                      {reservoir.title}
                    </option>
                  ))}
                </optgroup>
              </select>
            </div>
          </div>

          {settingSource.length > 0 && (
            <div>
              {settingSource.map((option) => (
                <div
                  key={option}
                  className="flex items-center justify-between bg-[#d3e7ff]
                            mb-3 border border-[#2387FF]
                             w-full rounded px-3 py-2"
                >
                  <span>{option}</span>
                  <button onClick={() => handleSettingSources(option)}>
                    <Icon icon="gridicons:cross" fontSize={24} color="black" />
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="flex justify-end mt-6">
            <button
              className="cursor-pointer flex items-center gap-2
                  hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2"
              onClick={previewBtnDataValidation}
            >
              {/* {isLoading && <Loader color="white" height="17px" width="17px" />} */}
              Design Prompt
            </button>
          </div>
        </div>
      </div>

      {/* Prompt */}
      <div
        className="p-6 text-[#555555] h-[100vh]"
        style={{ display: activeTab == "Prompt" ? "block" : "none" }}
      >
        <div className="bg-[#FFF2C2] rounded-md p-2.5 flex items-center gap-6">
          <Icon
            icon="heroicons:light-bulb"
            color="#191919"
            fontSize={24}
            className="ms-6"
          />
          <p className="w-[650px] leading-6 text-[15px] text-[#191919]">
            Customize the system prompt to get more control over the data you
            are giving the AI. This prompt helps the AI to understand how to
            process your data.
          </p>
        </div>

        <div className="mt-6">
          <label className="text-[12px] font-semibold">User Prompt</label>
          <span className="text-pink-700 text-[16px] ps-1">*</span>
          <div className="mt-[10px]">
            <textarea
              className="outline-none border font-normal border-[#878787]
                      rounded-md p-3 w-full h-[350px] text-[16px] text-[#555555]"
              // onChange={handlePrompt}
              onChange={(e) => setUpdatedPrompt(e.target.value)}
              defaultValue={props.activeLake?.prompt}
              placeholder=" The default prompt..."
            ></textarea>

            <div className="flex justify-end items-center mt-6">
              <div className="flex items-center text-base text-[#555555] gap-[10px]">
                <button
                  className="cursor-pointer flex items-center gap-2
                    hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2"
                  onClick={handleGeneratePlayground}
                >
                  {isLoading && (
                    <Loader color="white" height="17px" width="17px" />
                  )}
                  Preview
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Preview */}
      <div
        className="p-6 text-[#555555] h-[100vh]"
        style={{ display: activeTab == "Preview" ? "block" : "none" }}
      >
        <div className="bg-[#FFF2C2] rounded-md p-2.5 flex items-center gap-6">
          <Icon
            icon="heroicons:light-bulb"
            color="#191919"
            fontSize={24}
            className="ms-6"
          />
          <p className="w-[650px] leading-6 text-[15px] text-[#191919]">
            See the preview to make sure AI response is accurate. You can change
            the response and save it to the lake.
          </p>
        </div>

        <div className="mt-6">
          <label className="text-[12px] font-semibold">
            AI Response Preview
            {/* <span className="text-pink-700 text-[16px] ps-1">*</span> */}
          </label>
          <div className="mt-[10px]">
            <textarea
              ref={previewTextAreaRef}
              className="outline-none border font-normal border-t-[#878787] border-b-[#878787]
                      rounded-md p-3 w-full h-[350px] text-[16px] text-[#555555]"
              onChange={(e) => setPlaygroundResponse(e.target.value)}
              defaultValue={playgroundResponse}
              placeholder=" The AI response...."
            ></textarea>

            <div className="flex justify-end items-center mt-6">
              <div className="text-base text-[#555555]">
                <button
                  className="cursor-pointer flex items-center gap-2
                         hover:bg-[#4F9FFF] bg-[#2387FF] text-white rounded-md px-auto px-4 py-2"
                  onClick={handleGeneratePlayground}
                >
                  {isLoading && (
                    <Loader color="white" height="17px" width="17px" />
                  )}
                  Regenerate preview
                  {/* Save Lake */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
