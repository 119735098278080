import React from "react";
import { Oval } from "react-loader-spinner";

const Loader = (props) => {
  return (
    <Oval
      visible={true}
      height="50"
      width="50"
      color="#656ef5"
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass=""
      secondaryColor="gray"
      {...props}
    />
  );
};

export default Loader;
