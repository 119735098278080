/**
 * Throws an error with a specific message if the condition is false.
 *
 * @param {boolean} [cond] - The condition to check.
 * @param {string} [message] - The error message to throw if the condition is false.
 * @param {...string} args - Values to replace placeholders in the message.
 * @throws {Error} If the condition is false.
 */
function invariant(cond, message, ...args) {
  if (cond) {
    return;
  }

  throw new Error(
    "Internal Lexical error: invariant() is meant to be replaced at compile " +
      "time. There is no runtime version. Error: " +
      (message || "Unknown error")
  );
}

module.exports = invariant;
