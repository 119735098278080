"use strict";

const { CAN_USE_DOM } = require("./canUseDOM");

// Check for documentMode, which is specific to older versions of Internet Explorer
const documentMode =
  CAN_USE_DOM && "documentMode" in document ? document.documentMode : null;

// Detect if the platform is Apple
const IS_APPLE = CAN_USE_DOM && /Mac|iPod|iPhone|iPad/.test(navigator.platform);

// Detect if the browser is Firefox
const IS_FIREFOX =
  CAN_USE_DOM && /^(?!.*Seamonkey)(?=.*Firefox).*/i.test(navigator.userAgent);

// Detect if the browser supports InputEvent and getTargetRanges
const CAN_USE_BEFORE_INPUT =
  CAN_USE_DOM && "InputEvent" in window && !documentMode
    ? "getTargetRanges" in new window.InputEvent("input")
    : false;

// Detect if the browser is Safari
const IS_SAFARI =
  CAN_USE_DOM && /Version\/[\d.]+.*Safari/.test(navigator.userAgent);

// Detect if the platform is iOS
const IS_IOS =
  CAN_USE_DOM &&
  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
  !window.MSStream;

// Detect if the platform is Android
const IS_ANDROID = CAN_USE_DOM && /Android/.test(navigator.userAgent);

// Detect if the browser is Chrome
const IS_CHROME = CAN_USE_DOM && /^(?=.*Chrome).*/i.test(navigator.userAgent);

// Detect if the platform is Android and the browser is Chrome
const IS_ANDROID_CHROME = CAN_USE_DOM && IS_ANDROID && IS_CHROME;

// Detect if the browser is AppleWebKit (and not Chrome)
const IS_APPLE_WEBKIT =
  CAN_USE_DOM && /AppleWebKit\/[\d.]+/.test(navigator.userAgent) && !IS_CHROME;

module.exports = {
  IS_APPLE,
  IS_FIREFOX,
  CAN_USE_BEFORE_INPUT,
  IS_SAFARI,
  IS_IOS,
  IS_ANDROID,
  IS_CHROME,
  IS_ANDROID_CHROME,
  IS_APPLE_WEBKIT,
};
