import React from 'react'

const RollPill = ({ role }) => {
    return (
        <label className='text-[16px] pr-2'>
            <span className='border border-gray-300 rounded-full px-3 py-1'>{role}</span>
        </label>
    )
}

export default RollPill