import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../config/Api_url";

const PostRequest = async (path, data = {}) => {
  const token = window.localStorage.getItem("token");
  const organization = window.localStorage.getItem("organization");

  const header = {
    "content-type": "application/json",
    Authorization: token,
    organization: organization,
  };

  try {
    const response = await axios.post(`${API_URL}${path}`, data, {
      headers: header,
      crossDomain: true,
    });

    const responseData = response.data;

    if (responseData.code == 200) {
      // throw new Error(responseData.data);
      return responseData.data;
    } else {
      // toast.error(responseData.data);
    }
  } catch (error) {
    toast.error(error);
  }
};

export default PostRequest;
