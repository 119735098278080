import React, { useState } from 'react'

const ActivePill = (props) => {

    return (
        <div className='text-[16px]'>
            <span className='cursor-pointer rounded-full px-3 py-1 bg-[#93FFB1] text-[#136826]'>{props.title}</span>
        </div>
    )
}

export default ActivePill