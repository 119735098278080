import React, { useEffect, useRef, useState } from "react";
import { EDIT, FILTER, REFRESH } from "../../images";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadTasksDetails } from "../../store/data.action";
import moment from "moment";
import PostRequest from "../../service/PostRequest";
import { toast } from "react-toastify";
import Loader from "../Loader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);
  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div
              className="relative w-[700px] h-auto bg-white rounded-[10px] overflow-hidden"
              ref={modalRef}
            >
              <div className="p-6">{children}</div>
              <button
                className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-600"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const TaskMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  const { organizationId, projectId } = props;

  const _tasksDetails = useSelector((state) => state.dataReducer.tasksDetails);

  const getTasksDetails = () => {
    const storedProject = params.project_id;
    if (storedProject) {
      dispatch(loadTasksDetails({ project: storedProject }));
    }
  };

  useEffect(() => {
    getTasksDetails();
    // console.log("params", params);
  }, [params.project_id]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="px-6 py-4 border-b border-gray-300 flex items-center justify-between text-[#555555] font-semibold text-base">
        <div className="flex items-center gap-1 text-[#555555]">
          <NavLink
            to={`/${organizationId}/${projectId}/tasks/general-list`}
            className="px-4 py-2 rounded-md cursor-pointer hover:bg-[#F5F5F5] "
          >
            General List
          </NavLink>
          <NavLink
            to={`/${organizationId}/${projectId}/tasks/members`}
            className="px-4 py-2 rounded-md cursor-pointer hover:bg-[#F5F5F5] "
          >
            Members
          </NavLink>
          <NavLink
            to={`/${organizationId}/${projectId}/tasks/roles`}
            className="px-4 py-2 rounded-md cursor-pointer hover:bg-[#F5F5F5] "
          >
            Roles
          </NavLink>
          <NavLink
            to={`/${organizationId}/${projectId}/tasks/dates`}
            className="px-4 py-2 rounded-md cursor-pointer hover:bg-[#F5F5F5] "
          >
            Dates
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default TaskMenu;
