import React from "react";
import PostRequest from "../../service/PostRequest";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UpdateProfile = () => {
  const navigate = useNavigate();

  const updateProfile = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    object.isUpdated = true;
    console.log("object", object);

    try {
      const response = await PostRequest("/update/profile", object);

      if (response) {
        // toast.success("Update Profile Successfully!");
        navigate(`/${response.organization}/organization/general`);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="bgImage"></div>
        <div className="min-h-screen flex items-center justify-start bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          <div className="ps-8">
            {/* <div className="max-w-md w-full bg-white border border-gray-200 rounded-lg shadow-md p-8"> */}
            <div>
              <h2 className="text-start text-2xl font-semibold text-gray-900">
                About Yourself
              </h2>
            </div>
            <form className="mt-4" onSubmit={updateProfile}>
              <div className="shadow-sm">
                <div className="mb-3">
                  <label className="text-[12px] text-[#555555] font-medium">
                    First Name
                  </label>
                  <input
                    required
                    name="firstName"
                    type="text"
                    className="appearance-none mt-1 rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="First Name..."
                  />
                </div>
                <div className="mb-3">
                  <label className="text-[12px] text-[#555555] font-medium">
                    Last Name
                  </label>
                  <input
                    required
                    name="lastName"
                    type="text"
                    className="appearance-none mt-1 rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Last Name..."
                  />
                </div>
                <div className="mb-3">
                  <label className="text-[12px] text-[#555555] font-medium">
                    Mobile Number
                  </label>
                  <input
                    required
                    name="phoneNumber"
                    type="number"
                    className="appearance-none mt-1 rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Your mobile number..."
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="mt-5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {/* {loader && (
                    <Loader color="white" height="18px" width="18px" />
                  )} */}
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
