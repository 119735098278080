import React from "react";
import Default from "../../components/theme/Default/Default";
import AIChat from "../../components/AIChat";
import { EDIT } from "../../images";
import Post from "../ProductStory/Post";

const Statistics = () => {
  return (
    <>
      <Default>
        <div className="flex">
          <div className="w-full">
            <div
              className="p-6 border-b border-gray-300 flex items-center justify-between
             text-[#555555] font-semibold text-base"
            >
              <div className="flex items-center gap-7 text-[#555555] cursor-pointer">
                <p>Updated: 19 Mar, 2024 at 12:23 AM</p>
              </div>

              <div className="flex items-center gap-5">
                <div className="flex gap-2 cursor-pointer p-2 rounded-md hover:bg-[#f5f5f5]">
                  <img src={EDIT} alt="edit" className="w-[24px] h-[24px]" />
                  <p>Edit</p>
                </div>
              </div>
            </div>

            <div className="h-screen overflow-y-scroll overflow-hidden">
              <div className="flex py-6 px-2 gap-[10px]">
                <div className="w-full h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    Tasks In Progress
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
                <div className="w-full h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    Tasks Completed
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    15
                  </p>
                </div>
                <div className="w-full h-[150px] px-4 py-[10px] rounded-[10px] border border-[#A5A5A5] flex flex-col justify-between">
                  <p className="font-semibold text-[18px] text-[#696969]">
                    All Tasks
                  </p>
                  <p className="font-semibold text-[48px] leading-10 text-[#696969]">
                    7
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[550px] customGray">
            <AIChat />
          </div>
        </div>
      </Default>
    </>
  );
};

export default Statistics;
