import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ADD, EDIT } from "../../images";
import { IoIosAdd } from "react-icons/io";
import PostRequest from "../../service/PostRequest";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import Loader from "../Loader";
// import "bootstrap/scss/bootstrap";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div
              className="relative w-[700px] bg-white rounded-[10px] overflow-hidden"
              ref={modalRef}
            >
              <div className="p-6">{children}</div>
              <button
                className="absolute top-0 right-0 m-4 mt-[25px] text-gray-500 hover:text-gray-600"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const OrganizationMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [optionsData, setOptionsData] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [textareaValue, setTextAreaValue] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationList, setOrganizationList] = useState([]);
  const [activeOrganization, setActiveOrganization] = useState("");
  const [activeOrganizationDetails, setActiveOrganizationDetails] = useState(
    {}
  );
  const [joinMember, setJoinMember] = useState(false);
  const [organizationEditModel, setOrganizationEditModel] = useState(false);
  const [inviteMember, setInviteMember] = useState(false);
  const [organizationCode, setOrganizationCode] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [memberRoleDescription, setMemberRoleDescription] = useState("");
  const [newOrganizationName, setNewOrganizationName] = useState("");

  const navigate = useNavigate();

  const { organizationId, projectId } = props;

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const inviteMemberModal = () => {
    setInviteMember(!inviteMember);
  };

  const joinMemberModal = () => {
    setJoinMember(!joinMember);
  };

  const editOrganization = () => {
    setOrganizationEditModel(!organizationEditModel);
  };

  const joinOrganizationCode = (event) => {
    setOrganizationCode(event.target.value);
  };

  const roleDescriptionPrompt = (event) => {
    setRoleDescription(event.target.value);
  };

  const joinOrganization = async () => {
    setIsLoader(true);
    try {
      const data = {
        organizationCode: organizationCode,
        roleDescription: roleDescription,
      };

      const response = await PostRequest("/organization/join", data);
      // console.log(response);
      toast.success("Join Organization Successfully");
      setJoinMember(false);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoader(false);
    }
  };

  const newMemberEmail = (event) => {
    setMemberEmail(event.target.value);
  };

  const newMemberRoleDescription = (event) => {
    setMemberRoleDescription(event.target.value);
  };

  const inviteNewMember = async () => {
    const data = {
      email: memberEmail,
      description: memberRoleDescription,
    };

    setIsLoader(true);

    try {
      const responseOfNewMember = await PostRequest("/invite/member", data);

      if (responseOfNewMember) {
        toast.success("Invitation has been sent successfully");
        setInviteMember(false);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoader(false);
    }
  };

  // const onSelect = (selectedList) => {
  //   const roles = selectedList.map((option) => option._id);
  //   setSelectedRole(roles);
  // };

  // const onRemove = (selectedList) => {
  //   const roles = selectedList.map((option) => option._id);
  //   setSelectedRole(roles);
  // };

  const getRoles = async () => {
    try {
      const response = await PostRequest("/roles");
      if (response) {
        setOptionsData(response);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePrompt = (event) => {
    setTextAreaValue(event.target.value);
  };
  const handleOrganizationName = (event) => {
    setOrganizationName(event.target.value);
  };

  const createOrganization = async () => {
    try {
      const data = {
        name: organizationName,
        roleDescription: textareaValue,
      };

      setIsLoader(true);

      const response = await PostRequest("/organization/create", data);

      if (response) {
        setIsOpen(false);
        // window.location.reload();
        toast.success("Create Organization");
        getOrganization(response._id);
        navigate(`/${response._id}/organization/general`);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoader(false);
    }
  };

  const getOrganization = async (active = false) => {
    try {
      const response = await PostRequest("/organizations");
      setOrganizationList(response);
      if (active) {
        setActiveOrganization(active);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handelNewOrganizationName = (event) => {
    setNewOrganizationName(event.target.value);
  };

  const updateOrganization = async () => {
    try {
      const data = {
        name: newOrganizationName,
      };

      const response = await PostRequest("/update/organization", data);

      if (response) {
        setOrganizationEditModel(false);
        toast.success("Update Organization Successfully!");
        getOrganization();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const createMember = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    object.roles = selectedRole;

    try {
      setIsLoader(true);
      const response = await PostRequest("/member/create", object);
      if (response) {
        setIsOpen(false);
        window.location.reload();
        toast.success("Member Created");
      }
      setIsLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const storeOrganization = (event) => {
    window.localStorage.setItem("organization", event.target.value);
    navigate(`/${event.target.value}/organization/general`);
  };

  useEffect(() => {
    setActiveOrganization(organizationId);
    const active = organizationList.find((o) => o._id == organizationId);
    setActiveOrganizationDetails(active);
  }, [organizationId, organizationList]);

  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <div className="px-6 py-4 border-b border-gray-300 flex items-center justify-between text-[#555555] font-medium w-auto">
        <div className="flex items-center gap-1 text-[#555555]">
          <NavLink
            to={`/${organizationId}/${projectId}/organization/general`}
            className="px-4 py-2 rounded-md cursor-pointer hover:bg-[#F5F5F5]"
          >
            General
          </NavLink>
          <NavLink
            to={`/${organizationId}/${projectId}/organization/team`}
            className="px-4 py-2 rounded-md cursor-pointer hover:bg-[#F5F5F5]"
          >
            Team
          </NavLink>
          <NavLink
            to={`/${organizationId}/${projectId}/organization/projects`}
            className="px-4 py-2 rounded-md cursor-pointer hover:bg-[#F5F5F5]"
          >
            Projects
          </NavLink>
        </div>
      </div>
      <div
        className="px-6 py-4 border-b border-gray-300 flex items-center justify-between
       text-[#555555] font-medium w-auto"
      >
        <div className="flex items-center gap-2.5">
          <div className="text-[16px] font-semibold">Organization:</div>
          <div
            className="w-[250px] px-2 h-10 border border-gray-400 
          rounded-md flex items-center justify-between text-[16px] font-semibold"
          >
            <select
              name="project-name"
              id="pname"
              className="w-[250px] h-9 cursor-pointer"
              onChange={storeOrganization}
              value={activeOrganization}
            >
              {organizationList?.map((organization, index) => (
                <option value={organization._id} key={index}>
                  {organization.name}
                </option>
              ))}
            </select>
          </div>

          <button
            className="p-2 rounded-md hover:bg-[#f5f5f5] 
          text-[18px] flex"
            onClick={toggleModal}
          >
            <img src={ADD} className="ps-3" />
            <span className="ps-2">Add New</span>
          </button>
        </div>

        {/* {loader && <Loader />} */}
        <div className=" font-semibold">
          <span className="text-[16px]">OrganizationId:</span>
          <span className="ps-1 text-[14px]">
            {activeOrganizationDetails?.code}
          </span>
        </div>
        <div className="flex items-center gap-5">
          <button
            className="p-2  flex gap-2 cursor-pointer text-base font-semibold
          rounded-md hover:bg-[#f5f5f5]"
            onClick={editOrganization}
          >
            <img src={EDIT} alt="edit" className="w-[24px] h-[24px]" />
            <p>Edit</p>
          </button>

          {/* Edit Organization Name */}

          <Modal
            className="w-[500px] h-auto"
            isOpen={organizationEditModel}
            onClose={editOrganization}
          >
            <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
              Update Organization Name
            </h1>

            <p className="text-[16px] w-[512px] text-[#555555] font-normal mb-6 tracking-[0.03em]">
              You can Update your own organization Name.
            </p>
            <div className="text-sm mb-2">
              Organization Name
              <span className="text-pink-700 text-[16px] ps-1">*</span>
            </div>
            <input
              type="text"
              required
              placeholder="Organization Name..."
              name="name"
              onChange={handelNewOrganizationName}
              className="mb-3 border border-gray-500 w-full rounded px-3 py-2 placeholder-gray-500 text-gray-900 rounded-t-md 
                focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />

            <div className="flex justify-end items-center mt-6">
              <div className="flex items-center text-base text-[#555555] gap-[10px]">
                <button
                  className="flex gap-2 bg-[#2387FF] hover:bg-[#4F9FFF]
                   text-white rounded-md px-[16px] py-2"
                  onClick={updateOrganization}
                >
                  {isLoader && (
                    <Loader color="white" height="20px" width="20px" />
                  )}
                  Update
                </button>
              </div>
            </div>
          </Modal>

          {/* Create Organization */}
          <Modal
            className="w-[500px] h-auto"
            isOpen={isOpen}
            onClose={toggleModal}
          >
            <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
              Create Organization
            </h1>
            <p className="text-[16px] w-[512px] text-[#555555] font-normal mb-6 tracking-[0.03em]">
              You can create your own organization or join any existing
              organization with code.
            </p>
            <div className="text-sm mb-2">
              Organization Name
              <span className="text-pink-700 text-[16px] ps-1">*</span>
            </div>
            <input
              type="text"
              required
              placeholder="Your Company Name..."
              name="name"
              onChange={handleOrganizationName}
              className="mb-3 border border-gray-500 w-full rounded px-3 py-2 placeholder-gray-500 text-gray-900 rounded-t-md 
                focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
            {/* <div className="text-sm mb-2"></div> */}

            {/* <Multiselect
                options={optionsData}
                displayValue="name"
                placeholder="Role"
                showCheckbox={true}
                onSelect={onSelect}
                onRemove={onRemove}
              /> */}
            <div className="text-sm mb-2">Role Description</div>

            <textarea
              className="outline-none border mb-6 font-normal border-[#878787]
               rounded-md p-3 w-[652px] h-[252px] text-[16px] text-[#555555]"
              onChange={handlePrompt}
              // defaultValue={defaultValue()}
              placeholder="Describe your role here..."
            ></textarea>

            <div className="flex justify-end items-center mt-6">
              <div className="flex items-center text-base text-[#555555] gap-[10px]">
                <div className="p-2 rounded-md hover:bg-[#f5f5f5] cursor-pointer">
                  Send Join Request
                </div>
                <button
                  className="flex gap-2 bg-[#2387FF] hover:bg-[#4F9FFF] text-white rounded-md px-[16px] py-2"
                  onClick={createOrganization}
                >
                  {isLoader && (
                    <Loader color="white" height="20px" width="20px" />
                  )}
                  Create Organization
                </button>
              </div>
            </div>
          </Modal>

          {window.location.href.match("/organization/general") && (
            <div
              className="bg-[#2387FF] hover:bg-[#4F9FFF] text-white
                  h-10 text-base font-semibold flex items-center justify-center
                 rounded-md px-[16px] cursor-pointer"
              onClick={joinMemberModal}
            >
              <span className="flex items-center justify-center">
                <IoIosAdd size={"24px"} /> Join Organization
              </span>
            </div>
          )}

          <Modal
            className="w-[500px] h-auto"
            isOpen={joinMember}
            onClose={joinMemberModal}
          >
            <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
              Send Join Request
            </h1>

            <p className="text-[16px] w-[512px] text-[#555555] font-normal mb-6 tracking-[0.03em]">
              You can create your own organization or join any existing
              organization with code.
            </p>
            <div className="text-sm mb-2">
              Organization ID
              <span className="text-pink-700 text-[16px] ps-1">*</span>
            </div>
            <input
              type="text"
              required
              placeholder="You Organization Id..."
              name="name"
              onChange={joinOrganizationCode}
              className="mb-3 border border-gray-500 w-full rounded px-3 py-2 placeholder-gray-500 text-gray-900 rounded-t-md 
                focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />

            <div className="text-sm mb-2">Role Description</div>

            <textarea
              className="outline-none border mb-6 font-normal border-[#878787]
               rounded-md p-3 w-[652px] h-[252px] text-[16px] text-[#555555]"
              onChange={roleDescriptionPrompt}
              // defaultValue={defaultValue()}
              placeholder="Describe your role here..."
            ></textarea>

            <div className="flex justify-end items-center mt-6">
              <div className="flex items-center text-base text-[#555555] gap-[10px]">
                <button
                  className="flex gap-2 bg-[#2387FF] hover:bg-[#4F9FFF]
                   text-white rounded-md px-[16px] py-2"
                  onClick={joinOrganization}
                >
                  {isLoader && (
                    <Loader color="white" height="20px" width="20px" />
                  )}
                  Send Join Request
                </button>
              </div>
            </div>
          </Modal>

          {window.location.href.match("/organization/team") && (
            <div
              className="bg-[#2387FF] hover:bg-[#4F9FFF] text-white
                  h-10 text-base font-semibold flex items-center justify-center
                 rounded-md px-[16px] cursor-pointer"
              onClick={inviteMemberModal}
            >
              <span className="flex items-center justify-center">
                <IoIosAdd size={"24px"} /> Invite Member
              </span>
            </div>
          )}

          <Modal
            className="w-[500px] h-auto"
            isOpen={inviteMember}
            onClose={inviteMemberModal}
          >
            <h1 className="text-[24px] text-[#191919] font-semibold mb-6">
              Invite Member
            </h1>

            <p className="text-[16px] w-[512px] text-[#555555] font-normal mb-6 tracking-[0.03em]">
              Send an invitation link to your member’s email to join the team.
              You can setup the role description for your member.
            </p>
            <div className="text-sm mb-2">
              Email
              <span className="text-pink-700 text-[16px] ps-1">*</span>
            </div>
            <input
              type="text"
              required
              placeholder="Member’s email here..."
              name="email"
              onChange={newMemberEmail}
              className="mb-3 border border-gray-500 w-full rounded px-3 py-2 placeholder-gray-500 text-gray-900 rounded-t-md 
                focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />

            <div className="text-sm mb-2">Role Description</div>

            <textarea
              className="outline-none border mb-6 font-normal border-[#878787]
               rounded-md p-3 w-[652px] h-[252px] text-[16px] text-[#555555]"
              onChange={newMemberRoleDescription}
              // defaultValue={defaultValue()}
              placeholder="Describe your role here..."
            ></textarea>

            <div className="flex justify-end items-center mt-6">
              <div className="flex items-center text-base text-[#555555] gap-[10px]">
                <div
                  className="cursor-pointer rounded-md hover:bg-[#f5f5f5] p-2"
                  onClick={() => setInviteMember(!inviteMember)}
                >
                  Cancel
                </div>
                <button
                  className="flex gap-2 bg-[#2387FF] hover:bg-[#4F9FFF]
                   text-white rounded-md px-[16px] py-2"
                  onClick={inviteNewMember}
                >
                  {isLoader && (
                    <Loader color="white" height="20px" width="20px" />
                  )}
                  Invite Member
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default OrganizationMenu;
