export const getFirstLetter = (string) => {

    if (!string) {

        return null

    }

    var matches = string.match(/\b(\w)/g);
    var acronym = matches.join('');
    var firstLetterArray = acronym.match(/\b(\w)/g);
    var firstLetter = firstLetterArray.join('');

    return firstLetter.toUpperCase();

}