import { io } from "socket.io-client";
import { SOCKET_URL } from "../config/Api_url";

const token = window.localStorage.getItem("token");
const organization = window.localStorage.getItem("organization");

const header = {
  "content-type": "application/json",
  Authorization: token,
  organization: organization,
};

const socket = io(SOCKET_URL, {
  extraHeaders: header,
});

socket.on("connect", () => {
  console.log("SOCKET CONNECTED");
});

export default socket;
