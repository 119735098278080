import React from 'react'
import Default from '../../components/theme/Default/Default'

const Home = () => {
  return (
    <Default>
       
    </Default>
  )
}

export default Home