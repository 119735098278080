import React, { useState } from "react";
import Tasks from "./Tasks";
import { useSelector } from "react-redux";
import MarkdownAiResponse from "../../components/MarkdownAiResponse/MarkdownAiResponse";

const Dates = () => {
  const _tasksDetails = useSelector((state) => state.dataReducer.tasksDetails);

  const [isStatus, setIsStatus] = useState("inProgressTasks");

  return (
    <>
      <Tasks isStatus={isStatus} setIsStatus={setIsStatus}>
        {/* <div className="p-[24px] tracking-[0.03em]">
          <div className="max-w-[650px]">
            <div className="text-[#555555] my-4 tracking-[0.03em]">
              {_tasksDetails?.completedTasks}
            </div>
          </div>
        </div> */}

        <div className="flex h-[calc(100vh-212px)] ">
          <div className="w-[380px] border-r border-gray-300 px-3 py-5">
            <div className="flex items-center gap-3 px-3 py-2.5">
              <div className="w-9 h-9 rounded-full bg-gray-300 flex items-center justify-center">
                <p className="font-bold text-white text-xl">
                  {/* {getFirstLetter(projectStory.member.name)} */}
                </p>
              </div>
              <p className="text-[#555555] font-semibold text-base">
                Unassigned Tasks
              </p>
            </div>

            <div className="active rounded-md px-3 py-2.5 mt-3">
              <div className="flex items-center gap-3 ">
                <div className="w-9 h-9 rounded-full bg-violet-500 flex items-center justify-center">
                  <p className="font-bold text-white text-xl">
                    {/* {getFirstLetter(projectStory.member.name)} */}W
                  </p>
                </div>

                <div>
                  <p className="text-black font-semibold text-[15px] leading-tight">
                    This Week (Three)
                  </p>
                  <p className="text-[#555555] font-normal text-[11px] leading-tight">
                    12 May, 24 - Now
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-3 px-3 py-2.5 mt-3">
              <div className="w-9 h-9 rounded-full bg-yellow-400 flex items-center justify-center">
                <p className="font-bold text-white text-xl">
                  {/* {getFirstLetter(projectStory.member.name)} */}A
                </p>
              </div>

              <div>
                <p className="text-[#555555] font-semibold text-[15px] leading-tight">
                  Week Two
                </p>
                <p className="text-[#555555] font-normal text-[11px] leading-tight">
                  6 May, 24 - 10 May, 24
                </p>
              </div>
            </div>
          </div>

          <div className="w-full p-6 h-[calc(100vh-212px)] overflow-y-auto overflow-hidden">
            <div className="rounded-xl px-6 py-5 border border-[#E6E6E6]">
              {/* <h4 className="text-[16px] font-medium text-[#191919]">
                Date - 19 March, 2024:
              </h4>
              <div className="my-5">
                <p className="text-[#555555] mb-1">
                  1. This is a task generated from meeting. <br /> This is the
                  description of this task. This is the description of this
                  task. This is the description of this task.
                </p>
                <p className="text-[#555555] mb-1">
                  2. Another task generated from the meeting with client.This is
                  the description of this task. This is the description of this
                  task. This is the description of this task. This is the
                  description of this task. This is the description of this
                  task. This is the description of this task.
                </p>
                <p className="text-[#555555] mb-1">
                  New task uploaded by project manager.
                </p>
                <p className="text-[#555555] mb-1">
                  Another task from the meeting.This is the description of this
                  task. This is the description of this task.
                </p>
              </div> */}
              <div
                className="text-[#555555]"
                // dangerouslySetInnerHTML={{ __html: _tasksDetails[isStatus] }}
              >
                <MarkdownAiResponse
                  markdownAiResponse={_tasksDetails[isStatus]}
                />
              </div>
            </div>
            {/* <div className="rounded-xl px-4 py-5 bg-[#EBF5FF] border border-[#B7D3F4] mt-[24px]">
              <h4 className="text-[16px] font-medium text-[#191919]">
                (Today) Date - 21 March, 2024:
              </h4>
              <div className="my-5">
                <p className="text-[#555555] mb-1">
                  1. This is a task generated from meeting. <br /> This is the
                  description of this task. This is the description of this
                  task. This is the description of this task.
                </p>
                <p className="text-[#555555] mb-1">
                  2. Another task generated from the meeting with client.This is
                  the description of this task. This is the description of this
                  task. This is the description of this task. This is the
                  description of this task. This is the description of this
                  task. This is the description of this task.
                </p>
                <p className="text-[#555555] mb-1">
                  New task uploaded by project manager.
                </p>
                <p className="text-[#555555] mb-1">
                  Another task from the meeting.This is the description of this
                  task. This is the description of this task.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </Tasks>
    </>
  );
};

export default Dates;
